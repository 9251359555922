import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import "./QuotesProposals.scss";
import { Row, Col, Tab, Tabs } from "react-bootstrap";

import {
  getQuoteMetricData,
  getQuoteSubsCrewSuppliers,
  getQuoteCartSuppliers,
  getQuoteMaterialSectionData,
  getQuoteLaborSectionData,
  getQuoteDebrisSectionData,
  getQuoteGuttersSectionData,
  getQuoteProposalData,
  saveEditedQuoteProposalData,
  editQuoteProposalData,
  getQuoteMatSuppliers,
  changeActiveProposal,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import { toast } from "react-toastify";

import {
  getQuoteMarkup,
  getDDData,
} from "lib/redux/drop-down/drop-down.action";

import { debounce, getUserPermissions } from "utils/utils";
import { selectPreferredSupplier, changeSupplier } from "./utils";

import Estimator from "./Estimator";
import InitialLoader from "components/InitilalLoader";
import { StaticTexts } from "./const";
import { getQuoteProposalModalData } from "lib/redux/quote-proposals/proposals.actions";
import { Link, useHistory } from "react-router-dom";
import userPermission from "hooks/usePermission";

import usePushData from "services/offlineDataPush";
import { LoadingLoader } from "components";

import { QuoteProposalLayout } from "./QuoteProposalLayout/QuoteProposalLayout";
import ProposalTab from "./ProposalTab/ProposalTab/ProposalTab";
import { JobNotes } from "./JobNotes/JobNotes";

export default function QuotesProposals(props) {
  const MAIN_TABS = [
    {
      name: "Estimator",
      id: "001",
    },
    {
      name: `${props.type ? `Proposal/Contract` : `Proposal`}`,
      id: "002",
    },
    {
      name: "Job Notes",
      id: "003",
    },
  ];

  const dispatch = useDispatch();

  // #region useSelector
  const { gettingQuoteProposalData, quoteProposalData, quoteProposalErr } =
    useSelector((state) => state.quoteProposal);
  const { dataList, gettingDDData, listErr } = useSelector(
    (state) => state.ddList
  );

  const { gettingQuoteMSuppliers, quoteMSuppliersData, quoteMSuppliersErr } =
    useSelector((state) => state.quoteMaterialSupp);

  const { gettingQuoteSCSuppliers, quoteSCSuppliersData, quoteSCSuppliersErr } =
    useSelector((state) => state.quoteSubsCrewSupp);
  const { user } = useSelector((state) => state.login);
  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const { quoteMaterialSectionData } = useSelector(
    (state) => state.quoteMaterialSectionData
  );
  const { quoteLaborSectionData } = useSelector(
    (state) => state.quoteLaborSectionData
  );
  const { quoteDebrisSectionData } = useSelector(
    (state) => state.quoteDebrisSectionData
  );
  const { quoteGuttersSectionData } = useSelector(
    (state) => state.quoteGuttersSectionData
  );
  const { gettingData, metrics, metricsErr } = useSelector(
    (state) => state.quoteMetric
  );

  const { data, errors, isLoading } = useSelector((state) => state.staticAll);

  const { gettingQuoteCSuppliers, quoteCSuppliersData, quoteCSuppliersErr } =
    useSelector((state) => state.quoteCartingSupp);
  // #endregion

  //#region useState
  const [loading, setLoading] = useState(true);

  const [LIST, setLIST] = useState([]);
  const [allTotals, setAllTotals] = useState({});
  const [subsCrewsSuppliers, setSubsCrewsSuppliers] = useState([]);

  const [address, setAddress] = useState(StaticTexts.loadingAddress);

  const [markUp, setMarkUp] = useState("");
  const [maxMarkUp, setMaxMarkUp] = useState("");
  const [minMarkUp, setMinMarkUp] = useState("");

  const [markUpAmt, setMarkUpAmt] = useState(0);
  const [totalWithoutMarkUp, setTotalWithoutMarkUp] = useState(0);
  const [totalWithMarkUp, setTotalWithMarkUp] = useState(0);
  const [showBuilding, setShowBuilding] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedBuildingName, setSelectedBuildingName] = useState("");

  const [excludedBuildings, setExcludedBuildings] = useState([]);
  const [excludedSections, setExcludedSections] = useState([]);
  const [showGutter, setShowGutter] = useState(false);
  const [showMaterial, setShowMaterial] = useState(false);
  const [craeteProposal, setCreateProposal] = useState(false);
  const [MANUFACTURERS, setMANUFACTURERS] = useState({});
  const [key, setKey] = useState(MAIN_TABS[0].id);

  const metricId = props.match?.params?.id || props.leadReqId;
  const { proposalId, proposalIdx } = useSelector(
    (state) => state.activeProposal
  );
  const [selectedMaterialSupplier, setSelectedMaterialSupplier] = useState("");
  const [selectedLaborSupplier, setSelectedLaborSupplier] = useState("");
  const [selectedDebrisSupplier, setSelectedDebrisSupplier] = useState("");
  const [selectedGuttersSupplier, setSelectedGuttersSupplier] = useState("");

  const [mateSuppliers, setMateSuppliers] = useState([]);
  const [cartSuppliers, setCartSuppliers] = useState([]);
  const [isSalesCard, setIsSalesCard] = useState(
    props?.location?.state?.isSalesCard || false
  );
  const [error, setError] = useState(false);

  // #endregion
  const { fetchFirstTime, firstTime } = useContactData();

  useEffect(() => {
    if (!quoteProposalData || (!proposalIdx && proposalIdx !== 0)) return;
    const currentProposal = quoteProposalData[proposalIdx];
    const first_time = currentProposal?.firstTime;
    fetchFirstTime(first_time);
  }, [proposalIdx, quoteProposalData]);

  console.log({quoteProposalData, proposalId});
  useLayoutEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!props.leadReqId) return;
    loadData();
  }, [props.leadReqId]);

  useEffect(() => {
    loadProposalModalData();
  }, [proposalId]);
  const { isPushing } = usePushData();

  const { qProposalPointerEvent } = userPermission(permission);
  useEffect(() => {
    if (metricId) {
      dispatch(getQuoteProposalData(metricId, isPushing));
    }
  }, [metricId]);

  // #region useEffects
  useEffect(() => {
    if (quoteProposalData && quoteProposalData.length > 0) {
      if (craeteProposal) {
        changeProposalId(
          quoteProposalData[quoteProposalData?.length - 1]._id,
          quoteProposalData?.length - 1
        );
        setCreateProposal(false);
      } else {
        if (
          proposalId !== quoteProposalData[proposalIdx]?._id ||
          proposalIdx > quoteProposalData.length - 1
        ) {
          changeProposalId(quoteProposalData[0]._id, 0);
        }
      }
    }
  }, [quoteProposalData, craeteProposal]);
  useEffect(() => {
    if (quoteProposalErr) {
      toast.error(quoteProposalErr);
    }
  }, [quoteProposalErr]);

  useEffect(() => {
    if (listErr) {
      toast.error(StaticTexts.taxesErr);
    }
  }, [listErr]);

  useEffect(() => {
    selectPreferredSupplier(
      quoteMSuppliersData,
      changeSupplier,
      setSelectedMaterialSupplier
    );
  }, [quoteMSuppliersData]);

  useEffect(() => {
    selectPreferredSupplier(
      subsCrewsSuppliers,
      changeSupplier,
      setSelectedLaborSupplier
    );
  }, [subsCrewsSuppliers]);

  useEffect(() => {
    selectPreferredSupplier(
      cartSuppliers,
      changeSupplier,
      setSelectedDebrisSupplier
    );
  }, [cartSuppliers]);

  useEffect(() => {
    selectPreferredSupplier(
      subsCrewsSuppliers,
      changeSupplier,
      setSelectedGuttersSupplier
    );
  }, [subsCrewsSuppliers]);

  useEffect(() => {
    if (quoteMSuppliersData) {
      setMateSuppliers(quoteMSuppliersData);
    }
  }, [quoteMSuppliersData]);

  useEffect(() => {
    if (quoteMSuppliersErr) {
      toast.error(quoteMSuppliersErr);
    }
  }, [quoteMSuppliersErr]);

  useEffect(() => {
    dispatch(getQuoteMarkup())
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setMarkUp(res.result.standard);
          setMinMarkUp(res.result.max);
          setMaxMarkUp(res.result.min);
        } else {
          onQuoteMarkupError();
        }
      })
      .catch((err) => onQuoteMarkupError());
  }, []);

  useEffect(() => {
    if (selectedDebrisSupplier && proposalId) {
      dispatch(
        getQuoteDebrisSectionData(selectedDebrisSupplier, metricId, proposalId)
      );
    }
  }, [selectedDebrisSupplier, proposalId]);

  useEffect(() => {
    if (showGutter && selectedGuttersSupplier && proposalId) {
      dispatch(
        getQuoteGuttersSectionData(
          selectedGuttersSupplier,
          metricId,
          proposalId
        )
      );
    }
  }, [selectedGuttersSupplier, proposalId, showGutter]);

  useEffect(() => {
    if (showMaterial && selectedMaterialSupplier && proposalId) {
      dispatch(
        getQuoteMaterialSectionData(
          selectedMaterialSupplier,
          metricId,
          proposalId
        )
      );
    }
  }, [selectedMaterialSupplier, proposalId, showMaterial]);

  useEffect(() => {
    if (showMaterial && selectedLaborSupplier && proposalId) {
      dispatch(
        getQuoteLaborSectionData(selectedLaborSupplier, metricId, proposalId)
      );
    }
  }, [selectedLaborSupplier, proposalId, showMaterial]);

  useEffect(() => {
    if (quoteSCSuppliersData && quoteSCSuppliersData.length > 0) {
      setSubsCrewsSuppliers(quoteSCSuppliersData);
    }
  }, [quoteSCSuppliersData]);

  useEffect(() => {
    if (quoteSCSuppliersErr) {
      toast.error(quoteSCSuppliersErr);
    }
  }, [quoteSCSuppliersErr]);

  useEffect(() => {
    if (data) {
      setLIST(data.materialPricingOptions);
      setMANUFACTURERS(data.manufacturers);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (quoteCSuppliersErr) {
      toast.error(quoteCSuppliersErr);
    }
  }, [quoteCSuppliersErr]);

  useEffect(() => {
    if (quoteCSuppliersData && quoteCSuppliersData.length > 0) {
      setCartSuppliers(quoteCSuppliersData);
    }
  }, [quoteCSuppliersData]);

  useEffect(() => {
    if (metricsErr) {
      setLoading(false);
    }
  }, [metricsErr]);

  useEffect(() => {
    //bug here
    if (gettingData || !navigator.onLine) {
      setLoading(false);
    }
  }, [gettingData]);

  useEffect(() => {
    if (metrics && metrics.roof) {
      //bug here
      setShowBuilding(metrics.roof.buildings?.length > 0);
      if (metrics.roof.hasGutters) {
        setShowGutter(true);
      } else {
        setShowGutter(false);
      }
      if (metrics.roof.hasRoof) {
        setShowMaterial(true);
      } else {
        setShowMaterial(false);
      }
    }
  }, [metrics, proposalId]);

  useEffect(() => {
    if (metrics && metrics.gutter) {
      setAddress(metrics.gutter.address);
    }
  }, [metrics]);

  useEffect(() => {
    calculateTotals();
  }, [
    quoteProposalData,
    quoteMaterialSectionData,
    quoteLaborSectionData,
    quoteDebrisSectionData,
    quoteGuttersSectionData,
  ]);
  // #endregion

  const changeProposalId = (id, idx) => {
    if (proposalId !== id) {
      dispatch(changeActiveProposal(id, idx));
    }
  };

  const getProposalData = (proposalIdx) => {
    if (quoteProposalData && quoteProposalData.length > 0) {
      return quoteProposalData[proposalIdx];
    }
    return undefined;
  };

  const calculateTotals = useCallback(() => {
    if (
      quoteProposalData ||
      quoteMaterialSectionData ||
      quoteLaborSectionData ||
      quoteDebrisSectionData ||
      quoteGuttersSectionData
    ) {
      const { totalMaterialLabor, markUpTotal, TotalSalePrice } =
        calculateTotalAtEnd({
          materialTotal: Number(
            quoteMaterialSectionData ? quoteMaterialSectionData.total : 0
          ),
          laborTotal: Number(
            quoteLaborSectionData
              ? quoteLaborSectionData.roofingLaborSubTotal
              : 0
          ),
          debrisTotal: Number(
            quoteDebrisSectionData
              ? quoteDebrisSectionData.debrisRemovalTotal
              : 0
          ),
          guttersTotal: Number(
            quoteGuttersSectionData
              ? quoteGuttersSectionData.guttersSubTotal
              : 0
          ),
          markUp:
            proposalDetail && isNaN(proposalDetail.markUp)
              ? 0
              : proposalDetail && proposalDetail.markUp
                ? Number(proposalDetail.markUp)
                : 0,
        });
      setMarkUpAmt(isNaN(markUpTotal) ? 0 : Number(markUpTotal).toFixed(2));
      setTotalWithoutMarkUp(Number(totalMaterialLabor).toFixed(2));
      setTotalWithMarkUp(Number(TotalSalePrice).toFixed(2));
    }
  }, [
    quoteProposalData,
    quoteMaterialSectionData,
    quoteLaborSectionData,
    quoteDebrisSectionData,
    quoteGuttersSectionData,
  ]);

  const reload = () => loadData();
  const onQuoteMarkupError = () => {
    toast.error(StaticTexts.quoteMarkUpErr);
  };
  const loadData = () => {
    if (data && !data.length) dispatch(fetchStaticAll());
    dispatch(getQuoteMatSuppliers());
    dispatch(getQuoteSubsCrewSuppliers());
    dispatch(getQuoteCartSuppliers());
    dispatch(getDDData("stateTax"));
    dispatch(getQuoteMetricData(props?.match?.params?.id || props.leadReqId));
  };

  const loadProposalModalData = () => {
    proposalId && dispatch(getQuoteProposalModalData(proposalId));
  };
  const calculateTotalAtEnd = useCallback(
    ({ materialTotal, laborTotal, guttersTotal, debrisTotal, markUp }) => {
      const totalMaterialLabor =
        materialTotal + laborTotal + guttersTotal + debrisTotal;
      const markUpTotal = (markUp / 100) * totalMaterialLabor;
      let TotalSalePrice = Number(totalMaterialLabor);

      if (markUpTotal) {
        TotalSalePrice += isNaN(markUpTotal) ? 0 : Number(markUpTotal);
      }

      return {
        totalMaterialLabor,
        markUpTotal,
        TotalSalePrice,
      };
    },
    [
      quoteProposalData,
      quoteMaterialSectionData,
      quoteLaborSectionData,
      quoteDebrisSectionData,
      quoteGuttersSectionData,
    ]
  );

  const changeData = (key, value) => ({
    ...getProposalData(proposalIdx),
    [key]: value,
  });

  const changeQuoteName = (name) => {
    if (name !== proposalDetail.quoteName) {
      const newData = changeData("quoteName", name);
      editItem(newData);
    }
  };

  const onChangeMarkup = (value) => {
    if (value !== proposalDetail.markUp) {
      const newData = changeData("markUp", value);
      editItem(newData);
    }
  };
  const changeProposalPercentage = (value) => {
    let newData = changeData("fixedStandardStatements", value);
    delete newData.firstTime;
    delete newData.currentStage;
    editItem(newData);
  };
  const changeProposalisSelected = (value) => {
    const newData = changeData("otherTexts", value);
    editItem(newData);
  };
  const changeProposalDisPlayText = (value) => {
    const newData = changeData("otherTexts", value);
    editItem(newData);
  };
  const changeTotalValue = (value) => {
    const newData = changeData("total", value);
    editItem(newData);
  };
  const getProposalsListData = () => [...quoteProposalData];

  const updateData = (data) => {
    const dataArr = getProposalsListData();
    dataArr.splice(proposalIdx, 1, data);
    saveData(dataArr, data);
  };

  const updateAutoTextData = (updatedAutoText) => {
    const proposalDetail = getProposalData(proposalIdx);

    const data = {
      ...proposalDetail,
      ...updatedAutoText,
    };

    updateData(data);
  };

  const proposalDetail = getProposalData(proposalIdx);

  const saveDataToDB = (data) => {
    dispatch({ type: "STARTING" });
    dispatch(saveEditedQuoteProposalData(data))
      .then((res) => res.data)
      .then((res) => {
        dispatch({ type: "ENDING" });
        if (res.success) {
          setError(false);
        }
      })
      .catch((err) => {
        setError(true);
        dispatch({ type: "ENDING" });
      });
  };

  const debounceSaveData = useRef(
    debounce((dataArr) => saveDataToDB(dataArr), 1000)
  );

  const saveData = (dataArr, dataItem) => {
    dispatch(editQuoteProposalData(dataArr));
    // dispatch(getQuoteProposalData(metricId));
    debounceSaveData.current(dataItem);
  };

  const editItem = (dataItem) => {
    const dataArr = getProposalsListData();
    dataArr.splice(proposalIdx, 1, dataItem);
    saveData(dataArr, dataItem);
  };

  const changeProposalStatus = (status) => {
    const dataItem = { ...quoteProposalData[proposalIdx], ...status };
    delete dataItem.firstTime;
    editItem(dataItem);
  };

  const resetExcludedData = () => {
    setExcludedBuildings([]);
    setExcludedSections([]);
    setShowGutter(false);
    setShowMaterial(false);
  };

  if (loading || gettingQuoteProposalData || metricsErr) {
    return <InitialLoader error={metricsErr} onReload={reload} />;
  }

  if (!metrics || !metrics.roof) {
    return <LoadingLoader />;
  }

  let { type } = props;

  const switchMainTabs = () => {
    switch (key) {
      case MAIN_TABS[0].id:
        return (
          <Estimator
            key={key}
            setKey={setKey}
            updateAutoTextData={updateAutoTextData}
            markUp={proposalDetail.markUp}
            markUpAmt={markUpAmt}
            totalWithMarkUp={totalWithMarkUp}
            totalWithoutMarkUp={totalWithoutMarkUp}
            metricId={metricId}
            gettingQuoteMSuppliers={gettingQuoteMSuppliers}
            setSelectedDebrisSupplier={setSelectedDebrisSupplier}
            setSelectedGuttersSupplier={setSelectedGuttersSupplier}
            setSelectedLaborSupplier={setSelectedLaborSupplier}
            setSelectedMaterialSupplier={setSelectedMaterialSupplier}
            setSelectedBuilding={setSelectedBuilding}
            setSelectedBuildingName={setSelectedBuildingName}
            showBuilding={showBuilding}
            dataList={dataList}
            selectedMaterialSupplier={selectedMaterialSupplier}
            mateSuppliers={mateSuppliers}
            setExcludedSections={setExcludedSections}
            excludedSections={excludedSections}
            setExcludedBuildings={setExcludedBuildings}
            selectedBuilding={selectedBuilding}
            selectedBuildingName={selectedBuildingName}
            excludedBuildings={excludedBuildings}
            metrics={metrics}
            changeProposalStatus={changeProposalStatus}
            changeQuoteName={changeQuoteName}
            quoteName={proposalDetail.quoteName}
            showGutter={showGutter}
            showMaterial={showMaterial}
            selectedGuttersSupplier={selectedGuttersSupplier}
            subsCrewsSuppliers={subsCrewsSuppliers}
            selectedDebrisSupplier={selectedDebrisSupplier}
            cartSuppliers={cartSuppliers}
            selectedLaborSupplier={selectedLaborSupplier}
            onChangeMarkup={onChangeMarkup}
            quoteProposalLength={
              quoteProposalData ? quoteProposalData.length : 0
            }
            resetExcludedData={resetExcludedData}
            setCreateProposal={setCreateProposal}
            quoteMaterialSectionData={quoteMaterialSectionData}
            changeProposalPercentage={changeProposalPercentage}
            changeProposalDisPlayText={changeProposalDisPlayText}
            isLockQuoteData={proposalDetail}
            maxMarkUp={maxMarkUp}
            minMarkUp={minMarkUp}
            changeTotalValue={changeTotalValue}
            type={type}
            quoteDebrisSectionData={quoteDebrisSectionData}
          />
        );

      case MAIN_TABS[1].id:
        if (!navigator.onLine) {
          return <p>Proposal not supported offline</p>;
        }
        return (
          <div className="_cont_tab __cont_quote">
            <div className="_quote_cont">
              <ProposalTab
                updateAutoTextData={updateAutoTextData}
                changeProposalPercentage={changeProposalPercentage}
                changeProposalisSelected={changeProposalisSelected}
                changeProposalDisPlayText={changeProposalDisPlayText}
                metricId={metricId}
                isLockQuoteData={proposalDetail}
                totalWithMarkUp={totalWithMarkUp}
                changeTotalValue={changeTotalValue}
                changeProposalStatus={changeProposalStatus}
                //modalShow={modalShow}
                //modalShowv2={modalShowv2}
                //show_modal={show_modal}
                //show_modal_v2={show_modal_v2}
                type={type}
                tabKey={key}
              />
            </div>
          </div>
        );

      case MAIN_TABS[2].id:
        return <JobNotes proposalId={proposalId} />;
      default:
        return null;
    }
  };
  return (
    <div
      className="wrapper"
      style={qProposalPointerEvent ? {} : { pointerEvents: "none" }}
    >
      {/* header */}
      <Row
        style={{ margin: "0 .2em", alignItems: "center", marginBottom: "15px" }}
      >
        <h4>
          <span style={{ fontWeight: "bold" }}>
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Quotes & Proposals",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <b style={{ color: "red", marginRight: "17px" }}>?</b>
            </Link>
            Quotes & Proposals - &nbsp; &nbsp; &nbsp;
            <span style={{ fontWeight: "bold", marginLeft: "-2rem" }}>
              {address}{" "}
            </span>
          </span>
        </h4>
      </Row>
      {quoteProposalData && proposalDetail && (
        <QuoteProposalLayout
          updateAutoTextData={updateAutoTextData}
          changeProposalId={changeProposalId}
          proposalsData={quoteProposalData}
          currentProposal={proposalId}
        >
          <div style={{ flex: 1 }}>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              {MAIN_TABS.map((tab) => (
                <Tab
                  key={tab.id}
                  eventKey={tab.id}
                  title={tab.name}
                  disabled={
                    tab.id === MAIN_TABS[1].id
                      ? !quoteProposalData[proposalIdx]?.isProposalCreated
                      : false
                  }
                >
                  {tab.id === key ? switchMainTabs() : null}
                </Tab>
              ))}
            </Tabs>
          </div>
        </QuoteProposalLayout>
      )}
    </div>
  );
}
