/* eslint-disable react/prop-types */
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, Row, Col, Alert } from "react-bootstrap";
import "../metric.scss";
import { useDispatch, useSelector } from "react-redux";
import userPermission from "hooks/usePermission";
import { FcLock } from "react-icons/fc";

export const Header = ({ metricType, buildingName, address, isQuoteLock }) => (
  <h4 className="titlegutter">
    <Link
      to={{
        pathname: "/dashboard/help",
        search: "Metric Roof Gutter Images",
      }}
      target="_blank"
      rel="noreferrer"
      style={{ cursor: "pointer" }}
    >
      <b style={{ color: "red" }}>?</b>
    </Link>
    &nbsp; &nbsp; &nbsp;
    {`${metricType} - `}
    <span style={{ fontWeight: "bold" }}>{`${address}${buildingName ? ` - ${buildingName}` : ""
      }`}</span>
    {isQuoteLock && <FcLock size={25} />}
  </h4>
);

export default function MetricHeader({
  metricType,
  onClickBuilding,
  openImages,
  addAnotherSection,
  disabledAddSection,
  disabledImageBtn,
  msg,
  msgType,
  address,
  buildingName,
  metricId,
  hasOtherMetric,
  isBuilding,
  children,
  isQuoteLock,
  type_page,
}) {

  console.log({
    hasOtherMetric
  });

  const isRoofing = (metricType) => metricType === "Roofing";

  const { user } = useSelector((state) => state.login);
  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const history = useHistory();

  const dispatch = useDispatch();

  const { matrixPointerEvent } = userPermission(permission);
  return (
    <div
      className="mt-4 mb-4"
      style={matrixPointerEvent ? {} : { pointerEvents: "none" }}
    >
      <Row>
        <Col>{msg && <Alert variant={msgType}>{msg}</Alert>}</Col>
      </Row>

      <Row>
        <Col md="6">
          <Header
            metricType={metricType}
            buildingName={buildingName}
            address={address}
            isQuoteLock={isQuoteLock}
          />
        </Col>
        <Col
          md="6 text-end"
        //  style={{ paddingLeft: "0" }}
        >
          <Row
            style={{
              justifyContent: "flex-end",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            {/* {isBuilding && (
              <Button
                className="modified_btn"
                style={{
                  float: "right",
                  maxWidth: "0",
                  fontWeight: "bold",
                  fontSize: "larger",
                  height: "3.6rem",
                }}
                onClick={onClickBuilding}
              >
                Buildings
              </Button>
            )} */}
            {hasOtherMetric && (
              <Link
                style={{ padding: "0", width: "auto" }}
                to={`/dashboard/metrics/${isRoofing(metricType) ? "gutters" : "roof"
                  }/${metricId}`}
              >
                {!type_page ? (
                  <Button
                    className="modified_btn"
                    style={{
                      float: "right",
                      fontWeight: "bold",
                      fontSize: "larger",
                      height: "3.6rem",
                    }}
                  >
                    {isRoofing(metricType) ? "Gutters" : "Roof"}
                  </Button>
                ) : (
                  ""
                )}
              </Link>
            )}

            {!type_page ? (
              <Button
                className="modified_btn"
                // disabled={disabledImageBtn}
                onClick={openImages}
                style={{
                  float: "right",
                  width: "0",
                  fontWeight: "bold",
                  fontSize: "larger",
                  height: "3.6rem",
                }}
              >
                Images
              </Button>
            ) : (
              ""
            )}

            {!type_page ? (
              <Button
                className="modified_btn"
                onClick={addAnotherSection}
                disabled={disabledAddSection}
                style={{
                  float: "right",
                  width: "0",
                  fontWeight: "bold",
                  fontSize: "larger",
                  height: "3.6rem",
                }}
              >
                +Section
              </Button>
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Row>
      {children}
    </div>
  );
}
