import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Row, Col, Button, Container } from "react-bootstrap";

import { mapStateToProps, mapDispatchToProps } from "./metricRoofingConnect";
import { toast } from "react-toastify";

import InitialLoader from "components/InitilalLoader";

import {
  ElevationDetailsMetric,
  InstallType,
  SectionListing,
  MetricTab,
  WorkTypeMetric,
} from "./components";

import MetricHeader, { Header } from "./components/MetricHeader";

import {
  FLOOR,
  ELEVATIONDETAILS,
  ELEVATION,
  DIFFICULTY,
  ELEVATIONOTHER,
} from "lib/constants/const";
import Dimensions from "./components/metric/dimensions";
import { useDispatch, useSelector } from "react-redux";
import userDetails from "hooks/user/userDetails";
import {
  resetAllMetrics,
  updateField,
  updateMetrics,
} from "./redux/roof-metric-collection/metric.action";
import {
  getMetrics,
  addMetric,
  editSectionMetric,
} from "./redux/metrics-v2/metrics.actions";
import { categoryMapping, objectHasValue } from "utils/utils";
import { uuid } from "uuidv4";
import PitchMetric from "./components/metric/pitch";
import FloorMetric from "./components/metric/floor";
import ElevationMetric from "./components/metric/elevation";

const MetricRoofing = (props) => {
  const { data, match, leadReqId, getRoofData, image_type, type_page } = props;

  const { companyId } = userDetails();
  console.log({ props, companyId });
  const metricId = match?.params?.id || leadReqId;
  const roofData = useSelector((state) => state.metric.metrics);
  const metric = useSelector((state) => state.metric);

  console.log({
    roofData,
    sectionId: roofData?.sectionId,
  });

  const tableData = useSelector((state) => state.metric.tableData);
  const reduxDispatch = useDispatch();
  console.log({ allMetrics: metric });
  const metricData = useSelector((state) => state.metricV2Data);
  // const { isLoading, data as metricDataInf, errors } = metricData;
  console.log("metricData", metricData);

  const currentAddedSection = metricData?.currentAddedSection;
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentAddedSection) {
      dispatch(updateMetrics(currentAddedSection));
    }
  }, [currentAddedSection]);

  useEffect(() => {
    if (
      Object.keys(metricData?.deleteResponse).length &&
      !metricData?.isLoadingDelete
    ) {
      loadData();
      toast.success("Successfully removed section");
      // dispatch(updateMetrics({}));
      dispatch(resetAllMetrics());
    }
  }, [metricData?.deleteResponse]);

  useEffect(() => {
    return () => {
      console.log("I am here.....", metric);

      dispatch(resetAllMetrics());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      metricData?.deleteError &&
      Object.keys(metricData?.deleteError).length &&
      !metricData?.isLoadingDelete
    ) {
      console.log({ errorRemoving: metricData?.deleteError });
      toast.error("Something went wrong while removing section removed.");
    }
  }, [metricData?.deleteError]);

  console.log(metricData?.deleteError);

  const styles = {
    col: {
      margin: 0,
      padding: "1px",
    },
  };

  const {
    roof,
    address,
    building,
    coopUnits,
    isLocked,
    isQuoteLock,
    floor,
    elevation,
    elevationDetails,
    pitch,
    workType,
    install_type,
    removal_types,
    // other_rems,
    msg,
    msgType,
    installTypes,
    dimensions,
  } = roofData;

  console.log({
    roofData,
  });

  console.log({ elevationDetails });
  // Example of updating single field once
  const updateMetricField = (field, value) => {
    reduxDispatch(updateField(field, value));
  };

  useEffect(() => {
    return () => {
      console.log("leaving...");
      // This code runs when the component unmounts
      reduxDispatch(resetAllMetrics({}));
    };
  }, [reduxDispatch]);

  const openImages = () => {
    if (isLocked) {
      updateMetricField("isQuoteLock", true);
    } else {
      updateMetricField("isQuoteLock", false);
    }
    updateMetricField("images", true);
  };

  const getBuildingName = (building) => getBuildingName(coopUnits, building);

  const showBuildingList = () => updateMetricField("showCoopModal", true);

  // components thats needed for this component
  const MainHeader = () => (
    <Header
      address={address}
      metricType="Roofing"
      buildingName={building ? getBuildingName(building) : ""}
    />
  );

  const isAddSectionDisabled = () => {
    return (
      !floor ||
      !elevation ||
      !elevationDetails ||
      !pitch ||
      !workType ||
      !install_type ||
      !removal_types.length ||
      removal_types.includes("Shingles") ||
      removal_types.includes("Flat")
    );
  };

  const disabledAddSection = isAddSectionDisabled();

  const removeElevationData = (elevation, name) => {
    const updatedElevation = [...elevation]; // Create a copy of the elevation array
    const index = updatedElevation.indexOf(name);

    if (index !== -1) {
      updatedElevation.splice(index, 1); // Remove the item from the copy
      updateMetricField("elevation", updatedElevation); // Update the state with the new array
    }
  };

  const addFloor = (label, id, name, exec) => {
    console.log({
      label,
    });

    if (label === "floor") {
      updateMetricField("floor", name);
    }
  };

  const addPitch = (label, id, name, exec) => {
    if (label === "pitch") {
      // Check if the current roofPitch is the same as the name being passed
      const newRoofPitch = roofData.pitch.roofPitch === name ? "" : name;

      updateMetricField("pitch", {
        ...roofData.pitch,
        roofPitch: newRoofPitch,
      });
    }
  };

  const handleMultipleMetric = (name) => {
    elevation.includes(name)
      ? removeElevationData(elevation, name)
      : updateMetricField("elevation", [...elevation, name]);
  };

  const handleClickElevationDetails = (name, type) => {
    console.log("object", name, type);
    // console.log({
    //   label,
    //   id,
    //   name,
    //   exec,
    //   type,
    // });

    if (type === "not_more") {
      updateMetricField("elevationDetails", {
        ...elevationDetails,
        type: name,
      });
    } else if (type === "more") {
      const more = roofData.elevationDetails.more || { lists: [] }; // Ensure more exists and has a list
      const list = more.lists || []; // Ensure list is an array

      const updatedList = list.includes(name)
        ? list.filter((item) => item !== name) // Remove name if it exists
        : [...list, name];

      const newMore = {
        ...more,
        lists: updatedList,
      };

      if (name === "Other" && !updatedList.includes(name)) {
        // newMore.other = ""; // Clear the other value if "other" is deselected
        console.log("POOO");
        newMore.other = "";
      }

      updateMetricField("elevationDetails", {
        ...roofData.elevationDetails,
        more: newMore,
      });
    }
  };

  const handleOtherPitchTextChange = (event) => {
    const { name, value } = event.target;
    updateMetricField("pitch", {
      ...roofData.pitch,
      optionalText: value,
    });
  };

  const handleWorkTypeMetricOnNameClick = (name) => {
    if (name === "remove") {
      return;
    }
    const newRemovalTypes = ["New", "Overlay"].includes(name)
      ? { name: "", type: "" }
      : roofData.workType.removalTypes;

    updateMetricField("workType", {
      ...roofData.workType,
      name: roofData.workType.name === name ? "" : name,
      removalTypes: newRemovalTypes,
    });
  };

  const handleWorkTypeMetricOnTypeClick = (type) => {
    const isSpecialType = ["Shingles", "Flat"].includes(type);

    updateMetricField("workType", {
      ...roofData.workType,
      removalTypes: {
        ...roofData?.workType?.removalTypes,
        type: roofData?.workType?.removalTypes?.type === type ? "" : type,
        name: isSpecialType ? roofData?.workType?.removalTypes?.name : "",
      },
    });
  };

  const handleWorkTypeMetricOnTypeNameClick = (typeName) => {
    updateMetricField("workType", {
      ...roofData?.workType,
      removalTypes: {
        ...roofData?.workType?.removalTypes,
        name:
          roofData?.workType?.removalTypes?.name === typeName ? "" : typeName,
      },
    });
  };

  const handleInstallTypeClickData = (type, data) => {
    console.log("handleInstallTypeClickData", { type, data });
    if (type === "Shingles") {
      updateMetricField("installTypes", {
        ...roofData.installTypes,
        shingles: {
          type: roofData.installTypes.shingles.type === data ? "" : data,
        },
        flat: {
          type: "",
        },
      });
    } else {
      updateMetricField("installTypes", {
        ...roofData.installTypes,
        shingles: {
          type: "",
        },
        flat: {
          type: roofData.installTypes.flat.type === data ? "" : data,
        },
      });
    }
  };

  const reverseCategoryMapping = Object.entries(categoryMapping).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {}
  );

  // Make API Call
  const addDimensionData = (dimension, operationType = "add") => {
    let dimensions = [];

    if (operationType === "delete") {
      dimensions = roofData?.dimensions.filter(
        (dim) => dim.id !== dimension?.id
      );
    }

    const newDimensionArray = [...roofData.dimensions, dimension];

    if (operationType === "add") {
      if (!dimension?.id) {
        dimension.id = uuid();
        dimensions = [...roofData.dimensions, dimension];
      } else {
        dimensions = roofData.dimensions?.map((dim) => {
          if (dim.id === dimension?.id) {
            return { ...dim, ...dimension };
          }
          return dim;
        });
      }
    }

    console.log({
      dimensions,
    });

    console.log("metric12344", metric, { dimension });
    updateMetricField("dimensions", dimensions);
    // return;
    if (metric?.metrics?.sectionId) {
      const editData = {
        ...roofData,
        dimensions: dimensions,
        requestId: metricId,
      };
      delete editData?.section;
      delete editData?._id;

      const newSection = Object.keys(editData).reduce((acc, key) => {
        const newKey = reverseCategoryMapping[key] || key;
        acc[newKey] = editData?.[key];
        return acc;
      }, {});

      dispatch(editSectionMetric(newSection)); // Wait for editSectionMetric to complete

      // dispatch()
      return;
    }

    console.log({ metric, dimension, newDimensionArray });
    // return;
    const elevation_details = {
      ...elevationDetails,
      more: elevationDetails?.more
        ? {
            ...elevationDetails.more,
            ...(elevationDetails.more.other === "" && { other: undefined }),
          }
        : undefined,
    };

    const pitchBodyData = pitch.optionalText
      ? pitch
      : { roofPitch: pitch.roofPitch };
    const work_type = workType?.removalTypes?.name
      ? workType
      : { name: workType.name };

    const installTypeBodyData = installTypes?.flat?.type
      ? { flat: { type: installTypes?.flat?.type } }
      : { shingles: { type: installTypes?.shingles?.type } };

    console.log({ elevation_details, elevationDetails });
    // calling api
    const body = {
      requestId: metricId,
      projectName: metricData.data.projectName,
      companyId: companyId,
      isLocked: false,
      coverImages: [],
      sections: [
        {
          floor: floor,
          elevation: elevation[0],
          elevationDetails: elevation_details,
          pitch: pitchBodyData,
          workType: work_type,
          installTypes: installTypeBodyData,
          dimensions: newDimensionArray,
        },
      ],
    };

    // if (metric?.Accessories) {
    //   body.sections = [{ ...body.sections, accessories: metric?.Accessories }]
    // }

    console.log("obodybject", body);
    reduxDispatch(addMetric(body));
  };

  // fetching the section data by request id
  const loadData = (resetData = false) => {
    const id = match?.params?.id || leadReqId;
    if (id) {
      if (resetData) dispatch(resetAllMetrics());

      reduxDispatch(getMetrics(id));
    }
  };

  const reload = () => {
    loadData();
  };

  useEffect(() => {
    loadData(true);
  }, [metricId]);

  useEffect(() => {
    if (image_type === "PM_IMAGE") {
      updateMetricField("images", true);
    }
  }, [image_type]);

  const addAnotherSection = () => {
    dispatch(resetAllMetrics());
  };

  const {
    isLoadingDelete,
    isLoading,
    isLoadingEdit,
    editResponse,
    deleteError,
    editError,
    errors,
  } = metricData || {};

  // useEffect(() => {
  //   if (editResponse?.data && !isLoadingEdit) {
  //     loadData()
  //   }
  // }, [editResponse, isLoadingEdit])

  const hasSections = metricData?.data?.sections?.length > 0;

  console.log({
    isLoadingDelete,
    isLoadingEdit,
    isLoading,
  });

  const isLoadingData = () => {
    return isLoadingDelete || isLoadingEdit || isLoading;
  };
  const getError = () => {
    return deleteError?.mesg || editError?.msg || errors?.msg;
  };

  function Btn({ onClick, disabled, children, variant }) {
    return (
      <Button
        style={{ float: "right" }}
        variant={variant || "primary"}
        // onClick={onClick}
        disabled={disabled || false}
      >
        {children}
      </Button>
    );
  }

  const SaveOrViewQuote = () => {
    // if (Object.keys(metricData?.editResponse).length || Object.keys(metricData?.isLoading).length ) {
    if (!roofData?.sectionId) {
      return <Btn onClick={() => {}}>View Quote</Btn>;
    }

    if (roofData?.sectionId) {
      return <Btn onClick={() => {}}>Save Quote</Btn>;
    }
  };

  // saveQuoteRes.success || !isDataChanged ? (
  //   <Btn onClick={viewQuote}>View Quote</Btn>
  // ) : (
  //   <QuoteBtn disabled={disableBtn} onClick={() => saveQuote(metricId)}>
  //     {saveQuoteRes.loading
  //       ? "Saving Changes..."
  //       : saveQuoteRes.err
  //       ? "Try again"
  //       : "Save Quote"}
  //   </QuoteBtn>
  // );
  console.log({
    metricData,
  });
  return (
    <Container fluid className="px-5 py-2">
      {/* Header */}
      <Row>
        <Col>
          <MetricHeader
            metricType="Roofing"
            buildingName={building ? getBuildingName(coopUnits, building) : ""}
            openImages={openImages}
            addAnotherSection={() => addAnotherSection()}
            disabledAddSection={!roofData?.sectionId}
            disabledImageBtn={tableData.length === 0}
            metricId={metricId}
            address={metricData?.data?.address || ""}
            isBuilding={data?.isBuilding ?? "false"}
            msg={msg}
            msgType={msgType}
            isQuoteLock={isLocked}
            type_page={type_page}
            hasOtherMetric={metricData?.data?.hasGutters}
            onClickBuilding={showBuildingList}
          />
        </Col>
      </Row>

      {/* First Row */}
      <Row className="mt-3">
        {/* Display floor */}
        <Col md="6" lg="4">
          <Row>
            <FloorMetric
              label="Floor"
              array={floor}
              data={FLOOR}
              addFloor={addFloor}
              isQuoteLock={isQuoteLock}
              prevMetric={
                (!(data && data.isBuilding) ||
                  (data && data.isBuilding && this.state.building)) &&
                !isQuoteLock
              }
            />
          </Row>
        </Col>
        {/* Display Elevation */}
        <Col md="6" lg="4" className="d-flex flex-column">
          <label className="btnheading">Elevation</label>
          <Row>
            <ElevationMetric
              metricsData={ELEVATION}
              selectedData={elevation}
              isDisable={!floor || isQuoteLock}
              isQuoteLock={isQuoteLock}
              handleClick={handleMultipleMetric}
            />
          </Row>
        </Col>

        {/* Displat elevation Details */}
        <Col lg="4">
          <ElevationDetailsMetric
            elevationDetailsData={ELEVATIONDETAILS}
            elevationSelectedData={elevationDetails?.type}
            isQuoteLock={isQuoteLock}
            label="Elevation Details"
            prevMetric={elevation?.length && !isQuoteLock}
            elevationOther={ELEVATIONOTHER}
            onClickBtn={handleClickElevationDetails}
            elevationOtherSelected={elevationDetails?.more?.lists}
            handleClickOtherInputValue={(event) => {
              const { name, value } = event.target;
              const more = roofData.elevationDetails.more || {
                lists: [],
                other: "",
              }; // Ensure more exists and has the correct structure

              updateMetricField("elevationDetails", {
                ...roofData.elevationDetails,
                more: {
                  ...more,
                  other: value,
                },
              });
            }}
            otherSelectValue={elevationDetails?.more.other}
          />
        </Col>
      </Row>
      {/* Second Row of metrics */}
      <Row className="mt-5">
        <Col md="4">
          <PitchMetric
            label="Pitch"
            array={pitch?.roofPitch}
            data={DIFFICULTY}
            size={"24%"}
            addFloor={addPitch}
            isQuoteLock={isQuoteLock}
            prevMetric={elevationDetails && !isQuoteLock}
          />
          {(pitch?.roofPitch === "Walkie" ||
            pitch?.roofPitch === "Non-Walkie") && (
            <Row className="input_info_pitch">
              <Form.Control
                type="text"
                name={"optionalText"}
                value={pitch.optionalText}
                onChange={handleOtherPitchTextChange}
                placeholder="Roof Pitch(Optional)"
              />
            </Row>
          )}
        </Col>
        <Col md="4">
          <WorkTypeMetric
            pitch={pitch}
            workType={workType}
            onHandleClick={handleWorkTypeMetricOnNameClick}
            handleClickTypeData={handleWorkTypeMetricOnTypeClick}
            handleOtherClickTypeData={handleWorkTypeMetricOnTypeNameClick}
            isQuoteLock={isQuoteLock}
          />
          {workType?.removalTypes?.type && (
            <Row className="input_info">
              <Form.Control
                type="text"
                value={
                  workType?.removalTypes?.name
                    ? ` ${workType?.removalTypes?.type} -  ${workType?.removalTypes.name}`
                    : workType?.removalTypes?.type
                }
                disabled={true}
              />
            </Row>
          )}
        </Col>
        <Col md="4">
          <Row>
            <Col md="12" lg="5">
              <InstallType
                installTypes={installTypes}
                isQuoteLock={isQuoteLock}
                handleClickData={handleInstallTypeClickData}
                workType={workType}
              />
              {(installTypes?.flat?.type || installTypes?.shingles?.type) && (
                <Row className="input_info mt-1">
                  {/* <Col> */}
                  <Form.Control
                    ype="text"
                    placeholder="Install Types"
                    disabled={true}
                    value={
                      installTypes?.flat?.type
                        ? installTypes?.flat?.type
                        : installTypes?.shingles?.type
                    }
                  />
                  {/* </Col> */}
                </Row>
              )}
            </Col>

            <Col lg="7">
              <Dimensions
                dimensions={dimensions}
                addDimensionData={addDimensionData}
                disabled={!objectHasValue(installTypes)}
              />
            </Col>
          </Row>

          {/* <Row className="mt-5">
          </Row> */}
        </Col>
      </Row>
      {/* handling sections */}
      {roofData?.sectionId ? (
        <>
          {/* tabs for accessories and etc */}
          <Row className="mt-3">
            {/* <Col> */}
            <MetricTab
              sectionsData={metricData?.data?.sections}
              requestId={metricId}
            />
            {/* </Col> */}
          </Row>
          <Row className="mt-3 pl-0">
            <Form.Group style={styles.formInput}>
              <Form.Label>Section Notes:</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="notes"
                // value={notes}
                style={{ resize: "none" }}
                // onChange={(e) => {
                //   this.handleChange(e);
                //   this.saveDataIntoDb();
                // }}
              />
            </Form.Group>
          </Row>
        </>
      ) : null}

      {!isLoadingData() ? (
        <>
          {metricData?.data?.sections?.length ? (
            <Row className="mt-4 mr-2">
              <SectionListing
                type={"roofing"}
                sesctionData={metricData?.data?.sections}
                requestId={metricId}
              />
            </Row>
          ) : null}
        </>
      ) : (
        <InitialLoader error={getError()} onReload={reload} />
      )}

      {metricData?.data?.sections?.length ? (
        <Row>
          <Col>{SaveOrViewQuote()}</Col>
        </Row>
      ) : null}
    </Container>
  );
};

MetricRoofing.propTypes = {
  data: PropTypes.any,
  // isLoading: PropTypes.bool,
  // errors: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  leadReqId: PropTypes.any,
  image_type: PropTypes.string.isRequired,
  match: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricRoofing);
