import { combineReducers } from "redux";

import {
  SubscriptionInfoEditReducer,
  SubscriptionInfoReducer,
} from "./base-pricing/base-pricing.reducer";
import { staticAllReducer } from "./static-all/static-all.reducer";
import {
  LoginReducer,
  GetUsersReducer,
  GetUsersRoleReducer,
  BasePlanReducer,
  GetBaseReducer,
  GetNotificationReducer,
  notificationUpdateReducer,
  GetBaseReducerSignup,
  GetCompnayReducer,
} from "./user/user.reducer";
import {
  CalendarUnScheduleReducer,
  CalendarReducer,
  AvPersonsReducer,
  CalendarScheduleReducer,
  CalendarEventsReducer,
} from "./calendar/calendar.reducer";
import {
  NewLeadReducer,
  FetchLeadsReducer,
  DeleteLeadReducer,
  EditLeadReducer,
  ScheduleLeadReducer,
  EmailTypesReducer,
  PhoneTypesReducer,
  PropertyTypesReducer,
  RelationTypesReducer,
  TasksRateReducer,
  EditLeadDataReducer,
} from "./new-lead/new-lead.reducer";
import {
  RepairTaskReducer,
} from "./repairs/repairs.reducer";
import {
  StageReducer,
  ChangeStageReducer,
  UpdateFollowUpReducer,
  UpdateFollowUpDataReducer,
} from "./sales/sales.reducer";
import { MetricsData, AddBuilding, AddMetric } from "./metrics/metrics.reducer";
import {
  GetMSuppliersReducer,
  PricingMaterialReducer,
  GetSubsSuppliersReducer,
  GetCrewSuppliersReducer,
  GetCartingSuppliersReducer,
  PricingLaborReducer,
  PricingCartingReducer,
  PricingCrewReducer,
  PreferdMaterialReducer,
  GetMSupplierOptionsReducer,
} from "./suppliers/suppliers.reducer";
import {
  GetDropDownReducer,
  GetDDDataReducer,
} from "./drop-down/drop-down.reducer";
import {
  QuoteMetricsReducer,
  QuoteMaterialSuppliersReducer,
  QuoteCartingSuppliersReducer,
  QuoteSubsCrewSuppliersReducer,
  QuoteMaterialSectionDataReducer,
  QuoteLaborSectionDataReducer,
  QuoteDebrisSectionDataReducer,
  QuoteGuttersSectionDataReducer,
  QuoteProposalReducer,
  ActiveProposalReducer,
  QuoteProposalModalReducer,
  ProposalTabReducer,
  lockQuoteReducer,
} from "./quote-proposals/quotes-proposals.reducer";
import {
  AutoTextFetchReducer,
  StandardStatementsFetchReducer,
} from "./auto-text/auto-text.reducer";
import { GetAllPermissionReducer } from "./permission/permission.reducer";
import { Fetch } from "./sync/sync.reducer";
import { ContractDetailReducer } from "./proposalTab/proposalTab.reducer";
import { Checkout } from "./checkout/checkout.reducer";
import { MetricReducer } from "screens/metric/redux/roof-metric-collection/metric.reducer";
import { MetricV2Data } from "screens/metric/redux/metrics-v2/metrics.reducer";
import { GutterMetricReducer } from "screens/metric/redux/gutter-metric-collection/gutter-metric.reducer";
import { GuttersMetricV2Data } from "screens/metric/redux/gutter-metrics-v2/metrics.reducer";

const rootReducer = combineReducers({
  userRoles: GetUsersRoleReducer,
  basePlan: BasePlanReducer,
  allBasePlan: GetBaseReducer,
  staticAll: staticAllReducer,
  login: LoginReducer,
  saveLead: NewLeadReducer,
  fetchLeads: FetchLeadsReducer,
  deleteLead: DeleteLeadReducer,
  editLead: EditLeadReducer,
  scheduleLead: ScheduleLeadReducer,
  calendarInputs: CalendarReducer,
  availablePersons: AvPersonsReducer,
  scheduleCalendar: CalendarScheduleReducer,
  calendarEvents: CalendarEventsReducer,
  unscheduleEvent: CalendarUnScheduleReducer,
  tasks: RepairTaskReducer,
  getStage: StageReducer,
  changeStage: ChangeStageReducer,
  updateFollowup: UpdateFollowUpReducer,
  updateFollowupData: UpdateFollowUpDataReducer,
  // getRepairReq: GetRepairRequestReducer,
  // updateRepairReq: UpdateRepairRequestReducer,
  metricsData: MetricsData,
  addMetric: AddMetric,
  addBuilding: AddBuilding,
  ddMenu: GetDropDownReducer,
  ddList: GetDDDataReducer,
  userList: GetUsersReducer,
  company: GetCompnayReducer,
  materialSuppliers: GetMSuppliersReducer,
  materialSuppliersOptions: GetMSupplierOptionsReducer,

  materialPricing: PricingMaterialReducer,
  /**
   * root reducer material freferd
   */
  materialPreferd: PreferdMaterialReducer,
  subcontractorSuppliers: GetSubsSuppliersReducer,
  crewSuppliers: GetCrewSuppliersReducer,
  cartingSupplier: GetCartingSuppliersReducer,
  laborPricing: PricingLaborReducer,
  cartingPricing: PricingCartingReducer,
  crewPricing: PricingCrewReducer,
  quoteMetric: QuoteMetricsReducer,
  autoTexts: AutoTextFetchReducer,
  standardStatements: StandardStatementsFetchReducer,
  quoteMaterialSupp: QuoteMaterialSuppliersReducer,
  quoteSubsCrewSupp: QuoteSubsCrewSuppliersReducer,
  quoteCartingSupp: QuoteCartingSuppliersReducer,
  quoteMaterialSectionData: QuoteMaterialSectionDataReducer,
  quoteLaborSectionData: QuoteLaborSectionDataReducer,
  quoteDebrisSectionData: QuoteDebrisSectionDataReducer,
  quoteGuttersSectionData: QuoteGuttersSectionDataReducer,
  quoteProposal: QuoteProposalReducer,
  activeProposal: ActiveProposalReducer,
  quoteProposalModal: QuoteProposalModalReducer,
  quoteProposalTab: ProposalTabReducer,
  emailTypess: EmailTypesReducer,
  phoneTypess: PhoneTypesReducer,
  propertyTypess: PropertyTypesReducer,
  relationTypess: RelationTypesReducer,
  allPermissions: GetAllPermissionReducer,
  subscriptionInfo: SubscriptionInfoReducer,
  subscriptioninfoedit: SubscriptionInfoEditReducer,
  allnotification: GetNotificationReducer,
  notificationupdate: notificationUpdateReducer,
  getbaseplan: GetBaseReducerSignup,
  tasksRate: TasksRateReducer,
  lockquote: lockQuoteReducer,
  contractDetail: ContractDetailReducer,
  editleaddata: EditLeadDataReducer,
  fetch: Fetch,
  checkout: Checkout,
  metricV2Data: MetricV2Data,
  metric: MetricReducer ,
  gutterMetricV2Data: GuttersMetricV2Data,
  gutterMetric: GutterMetricReducer
});

export default rootReducer;
