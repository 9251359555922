import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PopupModal from "components/popup-modal/popup-modal";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Numpad from "./Numpad";
import { useDispatch } from "react-redux";
import {
  EditValue,
  RemoveValue,
  resetOptionsSelected,
  SaveValue,
  toggleInputSelected,
  toggleOptionSelected,
  updateOtherOptionValue,
} from "../redux/roof-metric-collection/metric.action";
import { useSelector } from "react-redux";
import { categoryMapping, getTotalValue } from "utils/utils";
import { editSectionMetric } from "../redux/metrics-v2/metrics.actions";
const { uuid } = require("uuidv4");

// Define all styles at the top
const styles = {
  block: {
    paddingTop: "0.2em",
    fontSize: "2.1em",
    marginRight: "5px",
    // cursor: "pointer",
    // background: "transparent",
    // color: "#999",
    // borderColor: "#999",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    width: "380px",
    paddingTop: "0.2em",
    fontSize: "2.1em",
  },
  slideRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rowMargin: {
    margin: "1em auto",
  },
  carsl: {
    border: "none",
  },
  blockText: {
    fontSize: "1rem",
  },
};

const isTablet = window.innerWidth <= 1024; // Example for iPad width

const AccessoriesTabModal = ({
  showTabModal,
  section,
  selectedData,
  onHide,
  handleSelectedData,
  modalInfo,
  requestId,
}) => {
  const [selectedDimension, setSelectedDimension] = useState({});

  const modalHeader = `Enter ${modalInfo?.data?.name} ${modalInfo?.data?.additionalLabel}`;

  const { isLoadingEdit, editResponse, errors } = useSelector(
    (state) => state.metricV2Data
  );

  console.log({
    isLoadingEdit,
    editResponse,
    errors,
    modalInfo,
  });

  const dispatch = useDispatch();
  const metric = useSelector((state) => state.metric);

  console.log({ metric });
  const [edit, setEdit] = useState({
    isEdit: false,
    id: "",
  });

  const [viewTallyMode, setViewTallyMode] = useState(false);
  const [showNextContent, setShowNextContent] = useState(false);

  // Select the current values of the opened tab
  const [currentValue, setCurrentValue] = useState({});

  useEffect(() => {
    const value =
      metric?.metrics?.[modalInfo?.data?.categoryName]?.[modalInfo?.data?.key];
    setCurrentValue(value);
    console.log({ value });
  }, [metric?.metrics, modalInfo]);

  const reverseCategoryMapping = Object.entries(categoryMapping).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {}
  );

  const findCurrentTab = (metricOptions, categoryName, subTabName) => {
    const category = metricOptions.find((mt) => mt.name === categoryName);
    if (!category) return null;

    const subTab = category.subTabs.find((st) => st.name === subTabName);
    return subTab || null;
  };

  console.log({ currentValue, modalInfo });

  const saveValue = async ({ fromOptions, clearText = false }) => {
    const isNextContentAvailable = modalInfo?.data?.isNextAvailable;
    if (isNextContentAvailable && !fromOptions && !clearText) {
      setShowNextContent(true);
      return;
    }
    const id = uuid();
    const categoryName = modalInfo?.data?.categoryName;
    const key = modalInfo?.data?.key;
    let data = {
      category: categoryName,
      key: modalInfo?.data?.key,
      value: selectedDimension[modalInfo?.data?.unitKey],
      unitKey: modalInfo?.data?.unitKey,
      unit: modalInfo?.data?.unit,
      selectedDimension: selectedDimension,
      id: id,
    };

    if (modalInfo?.data?.options) {
      const currentTab = findCurrentTab(
        metric?.metricOptions,
        categoryName,
        modalInfo?.data?.name
      );
      console.log({ currentTab });
      if (currentTab) {
        const selectedOptions = Object.keys(
          currentTab.options?.values || {}
        ).reduce((acc, key) => {
          const values = currentTab.options.values[key]?.values || [];
          const selectedValue = values.find((value) => value.selected);

          const selectedOthers = selectedValue?.others;
          const selectedOtherInputs = selectedOthers?.inputs?.filter(
            (input) => input.selected
          );

          console.log("selectedOtherInputValue ", {
            ...selectedOthers,
            inputs: selectedOtherInputs,
          });

          if (selectedValue) {
            acc[key] = {
              name: currentTab.options.values[key].name,
              value: selectedValue.name,
              others: {
                ...selectedOthers,
                inputs: selectedOtherInputs,
              },
            };
          }
          return acc;
        }, {});

        console.log("selectedOptions", { selectedOptions });
        data = { ...data, options: selectedOptions };
      } else {
        console.log("Current tab not found");
      }
    }

    let apiData = {
      key: modalInfo?.data?.key,
      value: selectedDimension[modalInfo?.data?.unitKey],
      unitKey: modalInfo?.data?.unitKey,
      unit: modalInfo?.data?.unit,
      id: id,
      ...selectedDimension,
    };

    console.log({ categoryName });
    const section = {
      ...metric?.metrics,
      [categoryName]: {
        ...metric?.metrics?.[categoryName], // Spread existing data for categoryName
        [key]: [
          ...(metric?.metrics?.[categoryName]?.[key] || []), // Ensure key is an array
          apiData,
        ],
      },
    };

    const newSection = Object.keys(section).reduce((acc, key) => {
      const newKey = reverseCategoryMapping[key] || key;
      acc[newKey] = section?.[key];
      return acc;
    }, {});

    newSection.requestId = requestId;
    delete newSection?.section;
    delete newSection?._id;

    console.log({
      newSection,
    });
    try {
      dispatch(editSectionMetric(newSection)); // Wait for editSectionMetric to complete

      if (edit.isEdit) {
        console.log("cooooo");
        data.id = edit.id;
        dispatch(EditValue(data));
        setEdit({ isEdit: false, id: "" });
        setViewTallyMode(true);
      } else {
        console.log("cooooo");
        dispatch(SaveValue(data));
      }

      if (isNextContentAvailable && fromOptions) {
        setShowNextContent(false);
        resetOptions();
      }

      setSelectedDimension({});
    } catch (error) {
      console.error("Error updating section metric: ", error);
      // Handle the error accordingly
    }
  };

  const toggleKeypad = () => {
    setViewTallyMode((prevState) => !prevState);
  };

  const deleteCurrentValue = (currentRow) => {
    console.log({ currentRow });
    currentRow.category = modalInfo?.data?.categoryName;
    if (!currentRow.hasOwnProperty("key")) {
      currentRow.key = modalInfo?.data?.key;
    }
    dispatch(RemoveValue(currentRow));
  };

  // Edit vaue
  const editCurrentValue = (currentRow) => {
    currentRow.category = modalInfo?.data?.categoryName;
    setViewTallyMode(false);
    // setSelectedDimension(currentRow.value)

    const inputs = modalInfo?.data?.inputs;
    console.log({ currentRow, inputs, modalInfo });

    const editRow = currentValue?.find((cv) => cv.id === currentRow?.id);
    console.log({ editRow });
    const options = modalInfo?.options?.values;
    for (let input of inputs) {
      console.log("1", { [input?.key]: editRow[input?.key] });
      setSelectedDimension((prevState) => ({
        ...prevState,
        [input?.key]: editRow[input?.key],
      }));
    }

    const selectedOptions = editRow?.options;

    if (selectedOptions) {
      for (const option of Object.keys(selectedOptions)) {
        const data = {
          categoryName: modalInfo?.data?.categoryName,
          subTabName: modalInfo?.data?.name,
          optionKey: option,
          optionValueName: selectedOptions?.[option]?.value,
        };

        console.log({ data });
        dispatch(toggleOptionSelected(data));
      }
    }

    setEdit({
      isEdit: true,
      id: currentRow?.id,
    });
  };

  const toggleInputSelection = (input) => {
    console.log({ input });
    const toggleData = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      inputKey: input?.key,
    };
    console.log({ toggleData });
    dispatch(toggleInputSelected(toggleData));
  };

  const HideModal = () => {
    onHide();
    setSelectedDimension({});
    setViewTallyMode(false);
    setShowNextContent(false);
    if (modalInfo?.data?.options) {
      resetOptions();
    }
    setSelectedDimension({}); // Set all dimensions to an empty state

    setEdit({ isEdit: false, id: "" });
  };

  const resetOptions = () => {
    const data = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
    };

    dispatch(resetOptionsSelected(data));
  };

  /**
   *
   * @returns
   * @description This will render in the top like list of sections, unit and number of units
   */
  const topView = () => {
    // Check if modalInfo and necessary keys are present
    if (
      !modalInfo ||
      !modalInfo.data ||
      !modalInfo.data.unit ||
      !modalInfo.data.categoryName ||
      !modalInfo.data.key
    ) {
      return (
        <div>
          {/* Render a message or placeholder when necessary keys are not available */}
          <p>Missing necessary data in modalInfo</p>
        </div>
      );
    }

    let totalLabel = modalInfo.data.unit || "Linear Feet";
    const currentCategoryValue = metric?.metrics
      ? metric?.metrics[modalInfo.data.categoryName][modalInfo.data.key]
      : [];
    console.log({ currentCategoryValue });
    const total = currentCategoryValue?.length
      ? getTotalValue(currentCategoryValue)
      : 0;

    return (
      <Row>
        <Col>
          <div className="_lbl">Section</div>
          <p className="_txt">{modalInfo?.sectionNumber}</p>
        </Col>
        <Col>
          <div className="_lbl">Total {totalLabel}</div>
          <p className="_txt">{total}</p>
        </Col>
        <Col>
          <div className="_lbl">Entries</div>
          <p className="_txt">
            {currentCategoryValue && currentCategoryValue.length
              ? currentCategoryValue.length
              : 0}
          </p>
        </Col>
      </Row>
    );
  };

  const setSelectedOption = (btn, optionKey) => {
    const data = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      optionKey: optionKey,
      optionValueName: btn?.name,
    };

    console.log({ data });
    dispatch(toggleOptionSelected(data));
  };

  const checkIfSelected = (value) => {
    return value.selected;
  };

  const updateOtherOption = (value, option, optionKey, newValue = "") => {
    console.log({ value, option });

    const updateData = {
      categoryName: modalInfo?.data?.categoryName,
      subTabName: modalInfo?.data?.name,
      optionKey: optionKey,
      optionValueName: option?.name,
      optionOtherInputName: value?.name,
    };
    if (newValue) {
      updateData.newValue = newValue;
    }
    dispatch(updateOtherOptionValue(updateData));
  };

  const handleInputChange = (input, option, optionKey, e) => {
    const newValue = e.target.value;
    updateOtherOption(input, option, optionKey, newValue);
  };

  const renderInputField = (input, index, option, optionKey) => {
    console.log({ input, index, option, optionKey });
    return (
      <>
        {input.type === "input" && (
          <div>
            {index > 0 ? (
              <label style={{ textAlign: "center", width: "100%" }}>x</label>
            ) : null}{" "}
            <input
              onChange={(e) => handleInputChange(input, option, optionKey, e)}
              placeholder={input.name}
              min="0"
              type="number"
              className="form-control"
            />
          </div>
        )}
        {input.type === "button" && (
          <button
            onClick={() => updateOtherOption(input, option, optionKey)}
            className={`ClickableBtn ${
              checkIfSelected(input) ? "selected" : ""
            }`}
            style={{ width: "28%" }}
          >
            {input.name}
          </button>
        )}
      </>
    );
  };

  function updateSelectedValues(originalArray, updatedArray) {
    // Create a map from the updatedArray for quick lookup
    const updatedMap = new Map(
      updatedArray.map((item) => [item.name, item.selected])
    );

    // Iterate over the originalArray and update the selected value if a match is found
    return originalArray.map((item) => {
      if (updatedMap.has(item.name)) {
        return { ...item, selected: updatedMap.get(item.name) };
      }
      return item;
    });
  }

  // Function to process inputs and update selected values
  function processInputs(value, currentValue) {
    const inputArray = value?.others?.inputs;

    if (inputArray && inputArray?.length) {
      let updatedInputs = [];

      currentValue.forEach((cv) => {
        if (cv?.options) {
          Object.keys(cv?.options).forEach((cvKey) => {
            const option = cv?.options?.[cvKey];
            const valueName = option?.value;

            if (valueName === value?.name && option?.others?.inputs) {
              const updated = updateSelectedValues(
                inputArray,
                option.others.inputs
              );
              option.others.inputs = updated;
              updatedInputs = updated; // Capture the updated inputs
            }
          });
        }
      });

      console.log({
        currentValue,
        originalInputs: value?.others?.inputs,
        value,
      });
      return updatedInputs; // Return the updated inputs
    }

    return []; // Return an empty array if inputArray is not defined
  }

  const renderOtherValuesOption = (optionValues) => {
    console.log({ currentValue });
    return Object.keys(optionValues).map((option) => (
      <div
        className="otherOption"
        style={{
          display: "flex",
          flexDirection: optionValues[option]?.type || "row",
          marginBottom: "1.5rem",
          justifyContent: "space-around",
          marginTop: ".2rem",
        }}
        key={option}
      >
        {optionValues[option].values?.map((value) => {
          const inputArray = value?.others?.inputs;

          const updatedValues = processInputs(value, currentValue);

          console.log({ updatedValues });

          return (
            <React.Fragment key={value.name}>
              {/* <div className="row otherOption"> */}
              {checkIfSelected(value) ? (
                <div className="col" style={{ width: "100%" }}>
                  <label
                    className="btnheading"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {value?.others?.name}
                  </label>
                  <div
                    className="row"
                    style={{ justifyContent: "space-between" }}
                  >
                    {value?.others?.inputs?.map((input, index) =>
                      renderInputField(input, index, value, option)
                    )}
                  </div>
                </div>
              ) : null}
              {/* </div> */}
            </React.Fragment>
          );
        })}
      </div>
    ));
  };

  const renderOptions = (optionValues) => {
    console.log({ optionValues });
    return Object.keys(optionValues).map((option) => (
      <div key={option}>
        {optionValues[option]?.type === "row" && (
          <label
            className="btnheading"
            style={{ textAlign: "center", width: "100%" }}
          >
            {optionValues[option].name}
          </label>
        )}
        <div>
          {optionValues[option]?.type === "column" && (
            <label
              className="btnheading"
              style={{ textAlign: "center", width: "100%" }}
            >
              {optionValues[option].name}
            </label>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: optionValues[option]?.type || "row",
              marginBottom: "1.5rem",
              justifyContent: "space-around",
              marginTop: ".2rem",
            }}
          >
            {optionValues[option].values?.map((value) => (
              <React.Fragment key={value.name}>
                <button
                  className={`ClickableBtn ${
                    checkIfSelected(value) ? "selected" : ""
                  }`}
                  style={{ width: "28%" }}
                  onClick={() => setSelectedOption(value, option)}
                >
                  {value?.name}
                </button>
              </React.Fragment>
            ))}
          </div>
          {/* {optionValues[option].values?.map(value => (
            <React.Fragment key={value.name}>
              {(value.name === "Other" && optionValues[option]?.name === "Color(Optional)" && value.selected) && (
                <input className="form-control" min="0" type="number" style={{ width: '100%', padding: 5 }} />
              )}
            </React.Fragment>
          ))} */}
        </div>
      </div>
    ));
  };

  /**
   *
   * @returns
   * @description This is to render the value of inputs in the top of Numpads
   */
  const renderInputs = (inputs, selectedDimension, fromNextSection = false) => {
    const blockWidth =
      inputs?.length === 1
        ? "23.5rem"
        : inputs?.length === 2
        ? "11.5rem"
        : isTablet
        ? "7.5rem" // Adjust this value as needed
        : "11.5rem"; // Default width when there are more than 2 inputs

    return inputs.map((input, index) => (
      <div
        key={index}
        style={{
          ...styles.block,
          width: blockWidth,
        }}
        onClick={() => toggleInputSelection(input)}
        className={`_block ${input.selected ? "selected" : "unselected"} ${
          fromNextSection && "unselected"
        }`}
      >
        {inputs?.length > 1 && (
          <p style={styles.blockText} className="_block-txt">
            {input?.name}
          </p>
        )}
        <p style={styles.blockText} className="_block-txt">
          {selectedDimension[input?.key] || ""}
        </p>
      </div>
    ));
  };

  /**
   *
   * @returns
   * @description This will render if the item has next options
   */
  const renderNextOptions = () => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    const inputs = currentTab?.inputs;
    const activeInput = inputs?.find((ct) => ct.selected);

    const isDisabled =
      !activeInput?.key ||
      !(selectedDimension && selectedDimension[activeInput.key]);
    const optionValues = currentTab?.options?.values;
    const optionStyles = currentTab?.options?.style;

    return (
      <>
        <Row style={styles.flexCenter}>
          <div
            style={{
              ...(inputs?.length != 1
                ? {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                  }),
            }}
          >
            {inputs && inputs.length
              ? renderInputs(inputs, selectedDimension, true)
              : null}
          </div>
        </Row>
        <div
          style={{
            display: "flex",
            flexDirection: optionStyles?.type || "row",
            justifyContent: "space-around",
            marginTop: "1rem",
            marginLeft: "5rem",
            marginRight: "5rem",
          }}
        >
          {optionValues ? (
            renderOptions(optionValues, optionStyles)
          ) : (
            <div>No options available</div>
          )}
          {optionValues ? (
            renderOtherValuesOption(optionValues, optionStyles)
          ) : (
            <div>No options available</div>
          )}
        </div>
        <Modal.Footer style={{ border: "none" }}>
          <Button className="modified_btn" variant="danger" onClick={HideModal}>
            Close
          </Button>
          <Button
            className="modified_btn"
            onClick={() => saveValue({ fromOptions: true })}
            disabled={isDisabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </>
    );
  };

  /**
   *
   * @returns
   * @description This will render the content of modal for the numpad input
   */
  const renderModalContent = (modalInfo) => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    const inputs = currentTab?.inputs;
    const activeInput = inputs?.find((ct) => ct.selected);

    const isDisabled =
      !activeInput?.key ||
      !(selectedDimension && selectedDimension[activeInput.key]);

    return (
      <>
        <div style={styles.flexCenter}>
          <div
            style={{
              ...(inputs?.length != 1
                ? { display: "flex", flexDirection: "row" }
                : {}),
            }}
          >
            {inputs && inputs.length
              ? renderInputs(inputs, selectedDimension)
              : null}
          </div>
          <Numpad
            addIntoDimen={(data, num) => {
              console.log({
                data,
                num,
                key: activeInput?.key,
              });

              setSelectedDimension((prevState) => {
                if (activeInput?.key) {
                  // Check if num is not undefined or empty
                  if (num !== undefined && num !== "") {
                    return {
                      ...prevState,
                      [activeInput.key]:
                        (prevState[activeInput.key] || "") + num.toString(),
                    };
                  } else {
                    // If num is undefined or empty, just return the current state
                    return {
                      ...prevState,
                      [activeInput.key]: "",
                    };
                  }
                }
                return prevState;
              });
            }}
            selectDimen={"Starter"}
            handleAdd={() => saveValue({ fromOptions: false })}
            editDimen={""}
            disableAddOrSave={isDisabled}
            saveText={modalInfo?.data?.isNextAvailable ? "Next" : "Save"}
          />
        </div>
        <Modal.Footer style={{ border: "none" }}>
          <Button className="modified_btn" variant="danger" onClick={HideModal}>
            Close
          </Button>
          <Button className="modified_btn" onClick={toggleKeypad}>
            View Tally
          </Button>
        </Modal.Footer>
      </>
    );
  };

  console.log({
    showNextContent,
    viewTallyMode,
  });

  /**
   *
   * @returns
   * @description This will render the tally view
   */
  const renderTally = () => {
    const currentTab = findCurrentTab(
      metric?.metricOptions,
      modalInfo?.data?.categoryName,
      modalInfo?.data?.name
    );
    console.log("coming here ", { currentTab });

    // Check if currentTab is defined and has inputs and options
    if (currentTab && currentTab.inputs) {
      // Check if inputs is an array
      const inputsArray = Array.isArray(currentTab.inputs)
        ? currentTab.inputs
        : [];
      // Check if options.values is an object
      const optionsValues =
        typeof currentTab?.options?.values === "object"
          ? currentTab?.options?.values
          : {};

      if (inputsArray.length && currentValue && currentValue.length) {
        return (
          <div className="slide-right">
            <>
              <table className="dimen_table">
                <thead>
                  <tr>
                    <th></th>
                    {/* Render headers for inputs */}
                    {inputsArray.map((thead) => (
                      <th key={thead?.key}>{thead?.name}</th>
                    ))}

                    {/* Render headers for options */}
                    {Object.keys(optionsValues).map((thead) => (
                      <th key={thead}>{optionsValues[thead]?.name}</th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Render rows for each value in currentCategoryValue */}
                  {currentValue.map((value) => (
                    <tr key={value.id}>
                      <td>
                        <Button onClick={() => editCurrentValue(value)}>
                          Edit
                        </Button>
                      </td>
                      {/* Render input values */}
                      {inputsArray.map((thead) => (
                        <td key={`${value.id}-${thead.key}`}>
                          {value?.[thead.key]
                            ? value?.[thead.key]
                            : value?.value}
                        </td>
                      ))}

                      {/* Render selected option values */}
                      {optionsValues
                        ? Object.keys(optionsValues).map((optionKey) => (
                            <td key={`${value.id}-${optionKey}`}>
                              {value?.options?.[optionKey]?.value || "-"}
                            </td>
                          ))
                        : null}

                      {/* Render delete button */}
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => deleteCurrentValue(value)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>

            <Modal.Footer
              style={{ justifyContent: "space-between", border: "none" }}
            >
              <div>
                <Button
                  className="modified_btn"
                  style={{ float: "left" }}
                  onClick={toggleKeypad}
                >
                  Keypad
                </Button>
              </div>
              {showPagination()}
              <div>
                <Button
                  className="modified_btn"
                  variant="danger"
                  onClick={() => {
                    HideModal();
                  }}
                >
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </div>
        );
      }
    }
    return null;
  };

  const showPagination = () => (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <button
        aria-label="Prev"
        className="pagination_btns"
        // onClick={this.prevPage}
        // disabled={this.state.page_num === 1}
      >
        {/* {getIcon(faChevronLeft)} */}
        {"<"}
      </button>
      {/* <p className="page_num">{this.state.page_num}</p> */}
      <p className="page_num">1</p>

      <button
        aria-label="Next"
        className="pagination_btns"
        // disabled={
        //   this.state.page_num * this.state.max_item >=
        //   this.state[this.state.selectData].length
        // }
        // onClick={this.nextPage}
      >
        {/* {getIcon(faChevronRight)} */}

        {">"}
      </button>
    </div>
  );

  return (
    <PopupModal
      show={showTabModal}
      heading={modalHeader}
      onHide={() => {
        HideModal();
        if (viewTallyMode) setViewTallyMode(false);
      }}
      bold
      styles={styles.carsl}
      scrollable={false}
    >
      <div className="_carsl">
        <div className="slide-left view">
          {topView()}
          {!viewTallyMode
            ? showNextContent
              ? renderNextOptions()
              : renderModalContent(modalInfo)
            : renderTally()}
        </div>
      </div>
    </PopupModal>
  );
};

AccessoriesTabModal.propTypes = {
  label: PropTypes.string,
  showTabModal: PropTypes.bool,
  selectDimension: PropTypes.any,
  editDimension: PropTypes.bool,
  section: PropTypes.number,
  selectedData: PropTypes.any,
  onHide: PropTypes.func,
  totalLabel: PropTypes.string,
  modalInfo: PropTypes.any,
};

export default AccessoriesTabModal;
