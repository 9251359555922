import { metricOptions } from "lib/constants/const";
import { SAVE_VALUE, SHOW_NUMPAD, HIDE_MODAL, EDIT_VALUE, REMOVE_VALUE, TOGGLE_INPUT_SELECTED, TOGGLE_OPTION_SELECTED, RESET_OPTIONS_SELECTED, SET_FIELD, UPDATE_FIELD, UPDATE_METRIC_FIELD, UPDATE_METRICS, UPDATE_OTHER_OPTION_METRIC, RESET_ALL_METRICS, SET_CURRENT_LEAD_DATA } from "./metric.constants";

const METRIC_INITIAL_STATE = {
    modalData: {
        showModal: false,
        data: {}
    },
    metricOptions: [
        {
            name: 'Accessories',
            key: "accessories",
            subTabs: [
                {
                    name: "Leak Barrier",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Accessories",
                    btnTexts: "Save",
                    key: "leakBarrier",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Eve Drip",
                    additionalLabel: "Edge",
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Accessories",
                    btnTexts: "Save",
                    key: "eveDrip",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Rake Drip",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Accessories",
                    btnTexts: "Save",
                    key: "rakeDrip",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Hip & Ridge",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Accessories",
                    btnTexts: "Save",
                    key: "hipRidge",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Stater",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Accessories",
                    btnTexts: "Save",
                    isNextAvailable: false,
                    key: "starter",
                    activeModal: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
            ]
        },
        {
            name: 'Flashing',
            key: "flashing",
            subTabs: [
                {
                    name: "Vent Pipe",
                    additionalLabel: '',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Flashing",
                    btnTexts: "Save",
                    key: "ventPipe",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "count",
                            key: "count",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Valley",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Flashing",
                    btnTexts: "Save",
                    key: "valley",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Steps",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Flashing",
                    btnTexts: "Save",
                    key: "steps",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Chimney",
                    additionalLabel: 'Flashing',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Flashing",
                    btnTexts: "Save",
                    key: "chimney",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        },
                        {
                            name: "Face Size",
                            key: "faceSize",
                            selected: false
                        }
                    ]
                },
                {
                    name: "Wall",
                    additionalLabel: 'Flashing',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Flashing",
                    btnTexts: "Save",
                    key: "wall",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        },
                        {
                            name: "Face Size",
                            key: "faceSize",
                            selected: false
                        }
                    ]
                },
            ]
        },
        {
            name: 'Ventilation',
            key: "ventilation",
            subTabs: [
                {
                    name: "Ridge Vent",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Ventilation",
                    btnTexts: "Save",
                    key: "ridgeVent",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            name: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Louvers",
                    additionalLabel: '',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Ventilation",
                    btnTexts: "Save",
                    key: "louvers",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            unit: "count",
                            key: "count",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Fans",
                    additionalLabel: '',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Ventilation",
                    btnTexts: "Save",
                    key: "fans",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            unit: "count",
                            key: "count",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Hood Vent Small",
                    additionalLabel: '',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Ventilation",
                    btnTexts: "Save",
                    key: "hoodVentSmall",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            unit: "count",
                            key: "count",
                            selected: true
                        }
                    ]
                },
                {
                    name: "Hood Vent Large",
                    additionalLabel: '',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Ventilation",
                    btnTexts: "Save",
                    key: "hoodVentLarge",
                    activeModal: false,
                    isNextAvailable: false,
                    inputs: [
                        {
                            unit: "count",
                            key: "count",
                            selected: true
                        }
                    ]
                },
            ]
        },
        {
            name: 'Skylight',
            key: "skylight",
            subTabs: [
                {
                    name: "Add Skylight",
                    additionalLabel: '',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Skylight",
                    btnTexts: "Save",
                    key: "skylight",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            name: "Width",
                            key: "width",
                            selected: true
                        },
                        {
                            name: "Height",
                            key: "height",
                            selected: false
                        },
                        {
                            name: "count",
                            key: "count",
                            selected: false
                        }
                    ],
                    options: {
                        style: {
                            type: 'row'
                        },
                        values: {
                            flange: {
                                name: 'Flange',
                                type: 'column',
                                values: [
                                    {
                                        name: 'Copper',
                                        selected: false
                                    },
                                    {
                                        name: 'Aluminum',
                                        selected: false
                                    }
                                ]
                            },
                            curb: {
                                name: 'Curb',
                                type: 'column',
                                values: [
                                    {
                                        name: 'Low',
                                        selected: false
                                    },
                                    {
                                        name: 'High',
                                        selected: false
                                    }
                                ]
                            },
                            venting: {
                                name: 'Venting',
                                type: 'column',
                                values: [
                                    {
                                        name: 'Fixed',
                                        selected: false
                                    },
                                    {
                                        name: 'Manual',
                                        selected: false
                                    },
                                    {
                                        name: 'Electric',
                                        selected: false
                                    }
                                ]
                            },
                            hoodType: {
                                name: 'Hood Type',
                                type: 'column',
                                values: [
                                    {
                                        name: 'Glass',
                                        selected: false
                                    },
                                    {
                                        name: 'Glass Tinted',
                                        selected: false
                                    },
                                    {
                                        name: 'Acrylic',
                                        selected: false
                                    },
                                    {
                                        name: 'Acrylic Tinted',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
            ]
        },
        {
            name: 'Wood',
            key: "wood",
            subTabs: [
                {
                    name: "Plywood",
                    additionalLabel: '- Size & Type',
                    unit: "pieces",
                    unitKey: "pieces",
                    categoryName: "Wood",
                    btnTexts: "Save",
                    key: "plywood",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            name: "pieces",
                            key: "pieces",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            size: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: '3/8"',
                                        selected: false
                                    },
                                    {
                                        name: '1/2"',
                                        selected: false
                                    },
                                    {
                                        name: '1/2"',
                                        selected: false
                                    },
                                    {
                                        name: '5/8"',
                                        selected: false
                                    },
                                    {
                                        name: '3/4"',
                                        selected: false
                                    }
                                ]
                            },
                            type: {
                                name: 'Type',
                                type: 'row',
                                values: [
                                    {
                                        name: 'CDX',
                                        selected: false
                                    },
                                    {
                                        name: 'OSB',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Rafter & studs",
                    additionalLabel: '',
                    unit: "feet",
                    unitKey: "feet",
                    categoryName: "Wood",
                    btnTexts: "Save",
                    key: "rafterStuds",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            name: "feet",
                            key: "feet",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            size: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: '2 x 4',
                                        selected: true
                                    },
                                    {
                                        name: '2 x 6',
                                        selected: false
                                    },
                                    {
                                        name: '2 x 8"',
                                        selected: false
                                    },
                                    {
                                        name: '2 x 8',
                                        selected: false
                                    },
                                    {
                                        name: '3/4""',
                                        selected: false
                                    }
                                ]
                            },
                            type: {
                                name: 'Length',
                                type: 'row',
                                values: [
                                    {
                                        name: "8'",
                                        selected: false
                                    },
                                    {
                                        name: "10'",
                                        selected: false
                                    },
                                    {
                                        name: "12'",
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Furring strips",
                    additionalLabel: '- Size & Type',
                    unit: "feet",
                    unitKey: "feet",
                    categoryName: "Wood",
                    btnTexts: "Save",
                    key: "furringStrips",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            name: "feet",
                            key: "feet",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            size: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: '1 x 2 x 8',
                                        selected: false
                                    },
                                    {
                                        name: '1 x 3 x 8',
                                        selected: false
                                    },
                                    {
                                        name: '1 x 4 x 8',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
            ]
        },
        {
            name: 'Fascia and Capping',
            key: "fasciaCapping",
            subTabs: [
                {
                    name: "Fascia",
                    additionalLabel: '',
                    unit: "Linear Feet",
                    unitKey: "linearFeet",
                    categoryName: "Fascia and Capping",
                    btnTexts: "Save",
                    key: "fascia",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "Linear Feet",
                            key: "linearFeet",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            type: {
                                name: 'Type',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Wood',
                                        selected: false
                                    },
                                    {
                                        name: 'Composite',
                                        selected: false
                                    }
                                ]

                            },
                            size: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: '1 x 4',
                                        selected: false
                                    },
                                    {
                                        name: '1 x 6',
                                        selected: false
                                    },
                                    {
                                        name: '1 x 8',
                                        selected: false
                                    },
                                    { name: '1 x 12', selected: false }
                                ]
                            },
                            length: {
                                name: 'Length',
                                type: 'row',
                                values: [
                                    {
                                        name: "8'",
                                        selected: false
                                    },
                                    {
                                        name: "10'",
                                        selected: false
                                    },
                                    {
                                        name: "12'",
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Cappping",
                    additionalLabel: '',
                    unit: "feet",
                    unitKey: "feet",
                    categoryName: "Fascia and Capping",
                    btnTexts: "Save",
                    key: "capping",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "feet",
                            key: "feet",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            type: {
                                name: 'Type',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Flat',
                                        selected: false
                                    },
                                    {
                                        name: 'PVC',
                                        selected: false
                                    },
                                    {
                                        name: 'Mar-Free',
                                        selected: false
                                    }
                                ]

                            },
                            color: {
                                name: 'Color(Optional)',
                                type: 'row',
                                isOptional: true,
                                values: [
                                    {
                                        name: 'White',
                                        selected: false
                                    },
                                    {
                                        name: 'Brown',
                                        selected: false
                                    },
                                    {
                                        name: 'Other',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
            ]
        },
        {
            name: 'Misc Items',
            key: "misc Items",
            subTabs: [
                {
                    name: "Trim Metal",
                    additionalLabel: '',
                    unit: "feet",
                    unitKey: "feet",
                    categoryName: "Misc Items",
                    btnTexts: "Save",
                    key: "trimMetal",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "feet",
                            key: "feet",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            type: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Apron',
                                        selected: false
                                    },
                                    {
                                        name: 'J-Bend',
                                        selected: false
                                    },
                                    {
                                        name: 'Gravel Stop',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Chimney Caps",
                    additionalLabel: '- Shape & Material & Dimension',
                    unit: "count",
                    unitKey: "count",
                    categoryName: "Misc Items",
                    btnTexts: "Save",
                    key: "chimneyCaps",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "count",
                            key: "count",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            shape: {
                                name: 'Shape',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Round',
                                        selected: false,
                                        others: {
                                            name: 'Size / Dimensions (Optional)',
                                            key: "sizeAndDimensions",
                                            inputs: [
                                                {
                                                    name: 'Inches',
                                                    type: 'input',
                                                    key: 'inches',
                                                    value: ""
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        name: 'Square',
                                        selected: false,
                                        others: {
                                            name: 'Size / Dimensions (Optional)',
                                            key: "sizeAndDimensions",
                                            inputs: [
                                                {
                                                    name: 'Inches',
                                                    type: 'input',
                                                    key: 'inches',
                                                    value: ""
                                                },
                                                {
                                                    name: 'Width',
                                                    type: 'input',
                                                    key: 'width',
                                                    value: ""
                                                },
                                            ]
                                        }
                                    },
                                    {
                                        name: 'Rectangle',
                                        selected: false,
                                        others: {
                                            name: 'Size / Dimensions (Optional)',
                                            key: "sizeAndDimensions",
                                            inputs: [
                                                {
                                                    name: 'Inches',
                                                    type: 'input',
                                                    key: 'inches',
                                                    value: ""
                                                },
                                                {
                                                    name: 'Width',
                                                    type: 'input',
                                                    key: 'width',
                                                    value: ""
                                                },
                                            ]
                                        }
                                    }
                                ]
                            },
                            material: {
                                name: 'Material',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Aluminum',
                                        selected: false
                                    },
                                    {
                                        name: 'Copper',
                                        selected: false
                                    },
                                    {
                                        name: 'Galvanized',
                                        selected: false
                                    },
                                    {
                                        name: 'Stainless',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Hand Nails",
                    additionalLabel: '',
                    unit: "pound",
                    unitKey: "pound",
                    categoryName: "Misc Items",
                    btnTexts: "Save",
                    key: "handNails",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "pound",
                            key: "pound",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            type: {
                                name: 'Type',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Copper',
                                        selected: false
                                    },
                                    {
                                        name: 'Galvanized',
                                        selected: false
                                    },
                                    {
                                        name: 'Stainless',
                                        selected: false
                                    }
                                ]
                            },
                            size: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: '1"',
                                        selected: false
                                    },
                                    {
                                        name: '1.25"',
                                        selected: false
                                    },
                                    {
                                        name: '1.5"',
                                        selected: false
                                    },
                                    {
                                        name: '1.75"',
                                        selected: false
                                    },
                                    {
                                        name: '2"',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Common Nails",
                    additionalLabel: '- Type & Size',
                    unit: "pound",
                    unitKey: "pound",
                    categoryName: "Misc Items",
                    btnTexts: "Save",
                    key: "commonNails",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "pound",
                            key: "pound",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            type: {
                                name: 'Type',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Steel',
                                        selected: false
                                    },
                                    {
                                        name: 'Galvanized',
                                        selected: false
                                    }
                                ]
                            },
                            size: {
                                name: 'Size',
                                type: 'row',
                                values: [
                                    {
                                        name: '6d - 2"',
                                        selected: false
                                    },
                                    {
                                        name: '8d - 2.5"',
                                        selected: false
                                    },
                                    {
                                        name: '10d - 3"',
                                        selected: false
                                    },
                                    {
                                        name: '16d - 3.5""',
                                        selected: false
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    name: "Metal Sheet",
                    additionalLabel: '',
                    unit: "sheet",
                    unitKey: "sheet",
                    categoryName: "Misc Items",
                    btnTexts: "Save",
                    key: "metalSheet",
                    activeModal: false,
                    isNextAvailable: true,
                    inputs: [
                        {
                            unit: "sheet",
                            key: "sheet",
                            selected: true
                        }
                    ],
                    options: {
                        style: {
                            type: 'column'
                        },
                        values: {
                            material: {
                                name: 'Material',
                                type: 'row',
                                values: [
                                    {
                                        name: 'Aluminum',
                                        selected: false,
                                        others: {
                                            name: 'Dimensions & Guage',
                                            key: "dimension",
                                            type: 'row',
                                            inputs: [
                                                {
                                                    name: '.032 (4X8)',
                                                    type: 'button',
                                                    selected: false
                                                },
                                                {
                                                    name: '.032 (4X10)',
                                                    type: 'button',
                                                    selected: false
                                                },
                                                {
                                                    name: '.040 (4X8)',
                                                    type: 'button',
                                                    selected: false
                                                },
                                                {
                                                    name: '.040 (4X10)',
                                                    type: 'button',
                                                    selected: false
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        name: 'Copper',
                                        selected: false,
                                        others: {
                                            name: 'Dimensions & Ounce',
                                            key: "dimension",
                                            type: 'row',
                                            inputs: [
                                                {
                                                    name: '16oz (3X8)',
                                                    type: 'button',
                                                    selected: false
                                                },
                                                {
                                                    name: '16oz (3X10)',
                                                    type: 'button',
                                                    selected: false
                                                },
                                                {
                                                    name: '32oz (3X8)',
                                                    type: 'button',
                                                    selected: false
                                                },
                                                {
                                                    name: '32oz (3X10)',
                                                    type: 'button',
                                                    selected: false
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    }
                }
            ]
        }
    ],
    tableData: [],
    metrics: {
        section: "",
        sectionId: "",
        floor: "",
        elevation: "",
        elevationDetails: {
            type: "",
            more: {
                lists: [],
                other: "",
            },
        },
        pitch: {
            roofPitch: "",
            optionalText: "",
        },
        workType: {
            name: "",
            removalTypes: {
                name: "",
                type: "",
            },
        },
        installTypes: {
            flat: {
                type: "",
            },
            shingles: {
                type: "",
            },
        },
        Accessories: {
            starter: [],
            leakBarrier: [],
            eveDrip: [],
            rakeDrip: [],
            hipRidge: []
        },
        Flashing: {
            ventPipe: [],
            valley: [],
            steps: [],
            chimney: [],
            wall: []
        },
        Ventilation: {
            ridgeVent: [],
            louvers: [],
            fans: [],
            hoodVentSmall: [],
            hoodVentLarge: [],
        },
        Skylight: {
            skylight: []
        },
        Wood: {
            plywood: [],
            rafterStuds: [],
            furringStrips: []
        },
        'Fascia and Capping': {
            fascia: [],
            capping: []
        },
        'Misc Items': {
            trimMetal: [],
            chimneyCaps: [],
            handNails: [],
            commonNails: [],
            metalSheet: []
        },
        dimensions: [],
        // showCoopModal: false,
        // coopUnits: [],
        // newBuildingName: "",
        // tempNewBuildingName: "",
        // otherModal: false,
        // elevation_other: "",
        // edOther: "",
        // removal_types: [],
        // other_rems: "",
        // removalModal: false,
        // installTypeModal: false,
        // install_types: [],
        // dimensionModal: false,
        // notes: "",
        // label: "",
        // showWoodModal: false,
        // selectDimen: null,
        // editDimen: null,
        // viewTally: false,
        // selectData: [],
        // showWoodEntry: false,
        // dataKeys: [],
        // totalLabel: "",
        // activeWood: null,
        // showMiscModal: false,
        // showMiscEntry: false,
        // deleteData: null,
        // showDeleteModal: false,
        // building: null,
        // lwfData: [],
        // totalFlat: 0,
        // address: "Deogarh",
        // msg: "",
        // msgType: "",
        // editBuilding: null,
        // deleteBuilding: null,
        // buildingErr: null,
        // dltHead: "",
        // dltSubHead: "",
        // sectionData: [],
        // tableData: [],
        // roofPitch: "",
        // images: false,
        // isDataChanged: false,
        // editSection: null,
        // isQuoteLock: false,
        // isLocked: false,
    },
    currentLead: {}

};



export function MetricReducer(state = METRIC_INITIAL_STATE, action) {
    switch (action.type) {
        case SAVE_VALUE: {
            const { id, category, key, value, unitKey, unit, selectedDimension, options } = action.payload;

            console.log({ id, category, key, value, unitKey, unit, selectedDimension, options });
            // Create a new object to add to the array
            const newObject = {
                key: key,
                value: value,
                unitKey: unitKey, // Assuming a default value for unitKey
                unit: unit, // Assuming a default value for unit
                id: id,
                options: options,
                ...selectedDimension
            };
            console.log({ newObject: newObject });

            console.log(category, key, state?.metrics?.[category]);
            // Update the state immutably by pushing the new object into the array
            return {
                ...state,
                metrics: {
                    ...state?.metrics,
                    [category]: {
                        ...state?.metrics?.[category],
                        [key]: [
                            ...(state?.metrics?.[category]?.[key]), // Ensure the array exists
                            newObject // Add the new object
                        ]
                    }
                }
            };

        }
        case SHOW_NUMPAD:
            return {
                ...state,
                modalData: {
                    showModal: true,
                    data: action.payload
                }
            };
        case EDIT_VALUE: {
            const { category, key, id, selectedDimension, options } = action.payload;
            console.log({ pp: action.payload });
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    [category]: {
                        ...state?.metrics?.[category],
                        [key]: state?.metrics?.[category][key].map(item =>
                            item.id === id ? { ...item, ...selectedDimension, options: options } : item
                        )
                    }
                }
            };
        }
        case REMOVE_VALUE: {
            const { category, key, id } = action.payload;
            console.log({ category, key, id, metric: state.metrics });
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    [category]: {
                        ...state?.metrics?.[category],
                        [key]: state?.metrics?.[category]?.[key].filter(item => item.id !== id)
                    }
                }
            };
        }
        case HIDE_MODAL:
            return {
                ...state,
                modalData: {
                    showModal: false,
                    data: {}
                }
            };

        case TOGGLE_INPUT_SELECTED: {
            const { categoryName, subTabName, inputKey } = action.payload;

            const updatedMetricOptions = state.metricOptions.map(category => {
                if (category.name === categoryName) {
                    return {
                        ...category,
                        subTabs: category.subTabs.map(subTab => {
                            if (subTab.name === subTabName) {
                                return {
                                    ...subTab,
                                    inputs: subTab.inputs.map(input => ({
                                        ...input,
                                        selected: input.key === inputKey
                                    }))
                                };
                            }
                            return subTab;
                        })
                    };
                }
                return category;
            });

            return {
                ...state,
                metricOptions: updatedMetricOptions
            };
        }

        case TOGGLE_OPTION_SELECTED: {
            // Destructure the necessary data from the action payload
            const { categoryName, subTabName, optionKey, optionValueName } = action.payload;

            // Iterate over the metricOptions to find the relevant category
            const updatedMetricOptions = state.metricOptions.map(category => {
                // Check if the current category matches the provided categoryName
                if (category.name === categoryName) {
                    return {
                        ...category, // Spread the existing category properties
                        subTabs: category.subTabs.map(subTab => {
                            // Check if the current subTab matches the provided subTabName
                            if (subTab.name === subTabName) {
                                return {
                                    ...subTab, // Spread the existing subTab properties
                                    // Check if the subTab has options property
                                    options: subTab.options ? {
                                        ...subTab.options, // Spread the existing options properties
                                        // Check if the options have values property
                                        values: subTab.options.values ? {
                                            ...subTab.options.values, // Spread the existing values properties
                                            [optionKey]: {
                                                ...subTab.options.values[optionKey], // Spread the specific optionKey properties
                                                // Update the selected state of the values within the specific optionKey
                                                values: subTab.options.values[optionKey].values.map(value => ({
                                                    ...value, // Spread the existing value properties
                                                    // Toggle the selected state based on optionValueName
                                                    selected: value.name === optionValueName ? !value.selected : false
                                                }))
                                            }
                                        } : subTab.options.values // If no values property, keep it as is
                                    } : subTab.options // If no options property, keep it as is
                                };
                            }
                            return subTab; // If subTabName doesn't match, return the subTab as is
                        })
                    };
                }
                return category; // If categoryName doesn't match, return the category as is
            });

            // Return the new state with updated metricOptions
            return {
                ...state, // Spread the existing state properties
                metricOptions: updatedMetricOptions // Update the metricOptions property
            };
        }

        case UPDATE_OTHER_OPTION_METRIC: {
            const { categoryName, subTabName, optionKey, optionValueName, optionOtherInputName, newValue } = action.payload;

            const updatedMetricOptions = state.metricOptions.map(category => {
                if (category.name !== categoryName) return category;

                return {
                    ...category,
                    subTabs: category.subTabs.map(subTab => {
                        if (subTab.name !== subTabName) return subTab;

                        if (!subTab.options || !subTab.options.values) return subTab;

                        return {
                            ...subTab,
                            options: {
                                ...subTab.options,
                                values: {
                                    ...subTab.options.values,
                                    [optionKey]: {
                                        ...subTab.options.values[optionKey],
                                        values: subTab.options.values[optionKey].values.map(value => {
                                            if (value.name !== optionValueName) return value;

                                            return {
                                                ...value,
                                                others: {
                                                    ...value.others,
                                                    inputs: value.others.inputs.map(input => ({
                                                        ...input,
                                                        selected: input.name === optionOtherInputName ? !input.selected : false,
                                                        value: input.name === optionOtherInputName && input.type === 'input' ? newValue : input?.value
                                                    }))
                                                }
                                            };
                                        })
                                    }
                                }
                            }
                        };
                    })
                };
            });

            return {
                ...state,
                metricOptions: updatedMetricOptions
            };
        }





        case RESET_OPTIONS_SELECTED: {
            const { categoryName, subTabName } = action.payload;

            const updatedMetricOptions = state.metricOptions.map(category => {
                if (category.name === categoryName) {
                    return {
                        ...category,
                        subTabs: category.subTabs.map(subTab => {
                            if (subTab.name === subTabName) {
                                return {
                                    ...subTab,
                                    options: subTab.options ? {
                                        ...subTab.options,
                                        values: subTab.options.values ? Object.fromEntries(
                                            Object.entries(subTab.options.values).map(([key, option]) => [
                                                key,
                                                {
                                                    ...option,
                                                    values: option.values.map(value => ({
                                                        ...value,
                                                        selected: false
                                                    }))
                                                }
                                            ])
                                        ) : subTab.options.values
                                    } : subTab.options
                                };
                            }
                            return subTab;
                        })
                    };
                }
                return category;
            });

            return {
                ...state,
                metricOptions: updatedMetricOptions
            };
        }

        case UPDATE_FIELD:
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    [action.payload.field]: action.payload.value,
                },
            };
        case UPDATE_METRICS:
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    ...action.payload,
                    sectionId: action?.payload?._id
                },
            };

        case RESET_ALL_METRICS:
            return {
                ...state,
                metrics: {
                    section: "",
                    sectionId: "",
                    floor: "",
                    elevation: "",
                    elevationDetails: {
                        type: "",
                        more: {
                            lists: [],
                            other: "",
                        },
                    },
                    pitch: {
                        roofPitch: "",
                        optionalText: "",
                    },
                    workType: {
                        name: "",
                        removalTypes: {
                            name: "",
                            type: "",
                        },
                    },
                    installTypes: {
                        flat: {
                            type: "",
                        },
                        shingles: {
                            type: "",
                        },
                    },
                    Accessories: {
                        starter: [],
                        leakBarrier: [],
                        eveDrip: [],
                        rakeDrip: [],
                        hipRidge: []
                    },
                    Flashing: {
                        ventPipe: [],
                        valley: [],
                        steps: [],
                        chimney: [],
                        wall: []
                    },
                    Ventilation: {
                        ridgeVent: [],
                        louvers: [],
                        fans: [],
                        hoodVentSmall: [],
                        hoodVentLarge: [],
                    },
                    Skylight: {
                        skylight: []
                    },
                    Wood: {
                        plywood: [],
                        rafterStuds: [],
                        furringStrips: []
                    },
                    'Fascia and Capping': {
                        fascia: [],
                        capping: []
                    },
                    'Misc Items': {
                        trimMetal: [],
                        chimneyCaps: [],
                        handNails: [],
                        commonNails: [],
                        metalSheet: []
                    },
                    dimensions: [],
                },
                // metricOptions: state.metricOptions.map(option => ({
                //     ...option,
                //     subTabs: option.subTabs.map(subTab => ({
                //         ...subTab,
                //         inputs: subTab.inputs.map(input => ({
                //             ...input,
                //             selected: false
                //         }))
                //     }))
                // })),
                modalData: {}
            };
        case SET_CURRENT_LEAD_DATA:
            return {
                ...state,
                currentLead: action.payload
            }

        default:
            return state;
    }
}
