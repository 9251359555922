import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { SLOPED_TYPES, FLATTYPES } from "lib/constants/const";
import DisplayInstallTypeModal from "./DisplayInstallTypeModal";

const InstallType = ({
  installTypes,
  isQuoteLock,
  handleClickData,
  workType,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Col>
      <label className="btnheading">Install Type</label>
      <Row>
        <Col>
          <button
            disabled={isQuoteLock || !workType?.name}
            style={
              isQuoteLock &&
              (installTypes?.flat?.type === "001" ||
                installTypes?.flat?.type ||
                installTypes?.shingles?.type)
                ? {
                    backgroundColor: "#60abfb",
                    borderColor: "#60abfb",
                  }
                : {}
            }
            className={`ClickableBtn ${
              installTypes?.flat?.type === "001" ||
              installTypes?.flat?.type ||
              installTypes?.shingles?.type
                ? "selected"
                : ""
            } `}
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            Select
          </button>
        </Col>
      </Row>
      <DisplayInstallTypeModal
        show={modalOpen}
        hide={() => setModalOpen(!modalOpen)}
        label="Select Install Types"
        installTypeData={SLOPED_TYPES}
        heading1={"Shingles"}
        heading2={"Flat"}
        installTypeOtherData={FLATTYPES}
        installTypes={installTypes}
        handleClickData={handleClickData}
      />
    </Col>
  );
};

InstallType.propTypes = {
  installTypes: PropTypes.any,
  isQuoteLock: PropTypes.bool.isRequired,
  handleClickData: PropTypes.func.isRequired,
  workType: PropTypes.any,
};

export default InstallType;
