import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Welcome from "./screens/Welcome";
import Login from "./screens/Login/Login";
import Signup from "./screens/Signup/Signup";
import ForgotPass from "./screens/ForgotPassword/ForgotPass";
import PublicRoutes from "./lib/routes/PublicRoutes";
import ProtectedRoutes from "./lib/routes/ProtectedRoutes";
import { LoadingLoader } from "components";
import { validateToken } from "lib/redux/user/user.actions";
import Completion from "components/Stripe/Completeion";
import Fail from "components/Stripe/Fail";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllPermissions } from "lib/redux/permission/permission.actions";
import { useSelector } from "react-redux";
import DashboardPanel from "screens/dashboard-panel/dashboard-panel";
import { GetTasksRate } from "lib/redux/new-lead/new-lead.actions";

const AppRoute = () => {
  const [loading, setLoading] = useState(true);
  // const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);

  // Load the static and master data 
  useEffect(() => {
    // Load taxrate master data of company
    dispatch(GetTasksRate());
  }, [])

  useEffect(() => {
   if (user) dispatch(getAllPermissions());
  }, [user]);

  // when go back online
  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    dispatch(validateToken(disableLoading));
  }, [dispatch]);

  if (loading) {
    return <LoadingLoader />;
  }

  return (
    <div>
      <ToastContainer />
      <Router basename="/">
        <Route path="/" exact component={Welcome} />
        <PublicRoutes path="/login" component={Login} />
        <PublicRoutes path="/signup" component={Signup} />
        <ProtectedRoutes path="/dashboard" component={DashboardPanel} />
        <Route path="/forgotpassword/:email/:otp" component={ForgotPass} />
        <Route path="/completion" component={Completion} />
        <Route path="/fail" component={Fail} />
      </Router>
    </div>
  );
};

export default AppRoute;