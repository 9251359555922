import { axios } from "services";
import { staticAllTypes } from "./static-all.types";
import { getData, saveData } from "services/storageManager";
import { ApiEndpoints } from "lib/config/baseSettings";

export const fetchStaticAllSuccess = (data) => ({
  type: staticAllTypes.STATIC_ALL_SUCCESS,
  payload: data,
});

export const fetchStaticAllFailure = (errors) => ({
  type: staticAllTypes.STATIC_ALL_FAILURE,
  payload: errors,
});

export const fetchStaticAllStart = () => ({
  type: staticAllTypes.STATIC_ALL_START,
});

export const fetchStaticAll = () => {
  return (dispatch) => {
    dispatch(fetchStaticAllStart());

    return axios
      .get(ApiEndpoints.STATIC_ALL.STATIC_ALL)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          saveData("staticAll", data.result);
          dispatch(fetchStaticAllSuccess(data));
        } else {
          dispatch(fetchStaticAllFailure(data));
        }
      })
      .catch((err) => dispatch(fetchStaticAllFailure(err)));

    // if (!navigator.onLine) {
    //   const allStatic = getData("staticAll");
    //   const data = { result: allStatic };
    //   if (!navigator.onLine) {
    //     dispatch(fetchStaticAllSuccess(data));
    //   } else {
    //     dispatch(fetchStaticAllFailure({ error: "failure" }));
    //   }
    // } else
    //   return axios
    //     .get(ApiEndpoints.STATIC_ALL.STATIC_ALL)
    //     .then((res) => res.data)
    //     .then((data) => {
    //       if (data.success) {
    //         saveData("staticAll", data.result);
    //         dispatch(fetchStaticAllSuccess(data));
    //       } else {
    //         dispatch(fetchStaticAllFailure(data));
    //       }
    //     })
    //     .catch((err) => dispatch(fetchStaticAllFailure(err)));
  };
};
