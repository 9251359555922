import { HIDE_MODAL, SAVE_VALUE, SHOW_NUMPAD, EDIT_VALUE, REMOVE_VALUE, TOGGLE_INPUT_SELECTED, TOGGLE_OPTION_SELECTED, RESET_OPTIONS_SELECTED, SET_FIELD, UPDATE_FIELD, UPDATE_METRICS, UPDATE_OTHER_OPTION_METRIC, RESET_ALL_METRICS, SET_CURRENT_LEAD_DATA } from "./metric.constants";

export const SaveValue = (data) => ({
    type: SAVE_VALUE,
    payload: data,
});

export const ShowModal = (data) => ({
    type: SHOW_NUMPAD,
    payload: data,
});

export const HideModal = (data) => ({
    type: HIDE_MODAL,
    payload: data,
});

export const EditValue = (data) => ({
    type: EDIT_VALUE,
    payload: data,
});

export const RemoveValue = (data) => ({
    type: REMOVE_VALUE,
    payload: data,
});

export const toggleInputSelected = (data) => ({
    type: TOGGLE_INPUT_SELECTED,
    payload: data
});


export const toggleOptionSelected = (data) => ({
    type: TOGGLE_OPTION_SELECTED,
    payload: data
});

// Example action creator for resetting options
export const resetOptionsSelected = (data) => ({
    type: RESET_OPTIONS_SELECTED,
    payload: data
});

export const resetAllMetrics = () => ({
    type: RESET_ALL_METRICS,
    payload: {}
});

// To update the buttons inside the button
export const updateOtherOptionValue = (data) => ({
    type: UPDATE_OTHER_OPTION_METRIC,
    payload: data
});

// categoryName,
// subTabName,
// inputKey,
// optionKey,
// optionValueName



export const updateField = (field, value) => ({
    type: UPDATE_FIELD,
    payload: { field, value }
  });


  export const updateMetrics = (metrics) => {
    return {
      type: UPDATE_METRICS,
      payload: metrics,
    };
  };


  export const setCurrentLeadData = (data) => ({
    type: SET_CURRENT_LEAD_DATA,
    payload: data
});
