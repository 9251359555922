// metricsActions.js
import { addMetricApi, deleteMetricApi, editMetricApi, getMetricsApi } from '../../services/metricsService';
import { resetAllMetrics } from '../roof-metric-collection/metric.action';
import { metricsActionTypes } from './metrics.types';

export const addMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.METRIC_ADD_ROOFING_START });

    try {
      const responseData = await addMetricApi(data);

      if (responseData) {
        dispatch({
          type: metricsActionTypes.METRIC_ADD_ROOFING_SUCCESS,
          payload: responseData.metric.sections,
        });
      } else {
        dispatch({
          type: metricsActionTypes.METRIC_ADD_ROOFING_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.METRIC_ADD_ROOFING_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getMetrics = (requestId) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.METRIC_ROOFING_GET_START });

    try {
      const responseData = await getMetricsApi(requestId);
      console.log({responseData});
      if (responseData) {
 
        dispatch({
          type: metricsActionTypes.METRIC_ROOFING_GET_SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: metricsActionTypes.METRIC_ROOFING_GET_FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.METRIC_ROOFING_GET_FAILURE,
        payload: error.message,
      });
    }
  };
};


export const editSectionMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.SECTION_UPDATE_START });

    try {
      const responseData = await editMetricApi(data);
      console.log({responseData});
      if (responseData) {
        dispatch({
          type: metricsActionTypes.SECTION_UPDATE__SUCCESS,
          payload: responseData.data.sections,
        });
      } else {
        dispatch({
          type: metricsActionTypes.SECTION_UPDATE__FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.SECTION_UPDATE__FAILURE,
        payload: error.message,
      });
    }
  };
  
};


export const deleteSectionMetric = (data) => {
  return async (dispatch) => {
    dispatch({ type: metricsActionTypes.SECTION_DELETE_START });

    try {
      const responseData = await deleteMetricApi(data);
      console.log({responseData});
      if (responseData) {
        dispatch({
          type: metricsActionTypes.SECTION_DELETE__SUCCESS,
          payload: responseData,
        });
      } else {
        dispatch({
          type: metricsActionTypes.SECTION_DELETE__FAILURE,
          payload: responseData.error,
        });
      }
    } catch (error) {
      dispatch({
        type: metricsActionTypes.SECTION_DELETE__FAILURE,
        payload: error.message,
      });
    }
  };
  
};


