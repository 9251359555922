import React, { useState } from "react";
import PropTypes from "prop-types";

import { calculateTotalGutterMetric, calculateAllTotalOfSubTabGutter, categoryMapping, formatNumber, getShinglesV2, sumDimensonTotal, sumFlatDimenstionTotal, sumShinglesDimenstionTotal } from "utils/utils";

import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "components/DeleteModal";

import styles from "../SectionListing.module.css";
import { resetAllMetricsGutter, updateMetricsGutter } from "screens/metric/redux/gutter-metric-collection/gutter-metric.action";
import { deleteGutterSectionMetric } from "screens/metric/redux/gutter-metrics-v2/metrics.actions";

const GutterSectionListing = ({ type, data, editSection, requestId }) => {
    const dispatch = useDispatch();
    const gutterMetricOptions = useSelector((state) => state.gutterMetric?.metricOptions);
    const metricData = useSelector((state) => state.gutterMetric?.metrics);

    const [showDeleteModal, setShowDeleteModal] = useState(false);


    console.log({
        dataGutter: data
    });
    // Handles viewing a section
    const onView = (section) => {

        dispatch(resetAllMetricsGutter())
        // Initialize empty objects for guttersAndLeaders and fasciaAndCapping
        const defaultMetrics = {
            guttersAndLeaders: {
                gutters: {},
                leaders: {},
                screens: {},
                leaderHeads: {}
            },
            fasciaAndCapping: {
                fascia: {},
                capping: {},
            }
        };
    
        // Ensure all sections are included
        const updatedSection = {
            ...defaultMetrics, // Start with default metrics
            ...section, // Override with any provided section values
            guttersAndLeaders: {
                ...defaultMetrics.guttersAndLeaders,
                ...(section.guttersAndLeaders || {})
            },
            fasciaAndCapping: {
                ...defaultMetrics.fasciaAndCapping,
                ...(section.fasciaAndCapping || {})
            }
        };
    
        console.log({
            updatedSection: updatedSection,
            gutterMetricOptions: gutterMetricOptions // Assuming this is defined elsewhere
        });
    
        // Dispatch the updated section
        dispatch(updateMetricsGutter(updatedSection));
    };
    
    

    // Handles deleting a section
    const deleteSection = (data) => {
        console.log({
            data
        });
        const deleteData = {
            requestId: requestId,
            sectionId: metricData?.sectionId,
        };
        dispatch(deleteGutterSectionMetric(deleteData));
    };

    return (
        <div className={styles.tableContainer}>
            <table className="table table-hover">
                <thead>
                    <>
                        <tr>
                            <th></th>
                            <th colSpan={3} className={styles.tableHeader}>
                                Current Building Total
                            </th>

                            <th className={editSection ? styles.tableDataEdit : styles.tableData}>
                                <span className="totalData">

                                    {
                                        calculateAllTotalOfSubTabGutter(data, 'guttersAndLeaders', 'gutters', 'linearFeet', '(G)')
                                    }
                                </span>
                            </th>

                            <th className={editSection ? styles.tableDataEdit : styles.tableData}>
                                <span className="totalData">


                                    {
                                        calculateAllTotalOfSubTabGutter(data, 'guttersAndLeaders', 'leaders', 'linearFeet', '(L)')
                                    }
                                </span>
                            </th>

                            <th className={editSection ? styles.tableDataEdit : styles.tableData}>
                                <span className="totalData">
                                    {
                                        calculateAllTotalOfSubTabGutter(data, 'guttersAndLeaders', 'screens', 'linearFeet', '(S)')
                                    }
                                </span>
                            </th>

                            <th className={editSection ? styles.tableDataEdit : styles.tableData}>
                                <span className="totalData">
                                    {
                                        calculateAllTotalOfSubTabGutter(data, 'fasciaAndCapping', 'fascia', 'linearFeet', '(F)')
                                    }
                                </span>
                            </th>

                            <th className={editSection ? styles.tableDataEdit : styles.tableData}>
                                <span className="totalData">
                                    {
                                        calculateAllTotalOfSubTabGutter(data, 'fasciaAndCapping', 'capping', 'linearFeet', '(C)')
                                    }
                                </span>
                            </th>
                            
                            <th className={editSection ? styles.tableDataEdit : styles.tableData}>
                                <span className="totalData">
                                    {
                                        calculateAllTotalOfSubTabGutter(data, 'fasciaAndCapping', 'leaderHeads', 'linearFeet', '(H)')
                                    }
                                </span>
                            </th>

                            <th></th>

                        </tr>
                        <tr>
                            <th style={{ width: "10%" }}>Action</th>
                            <th className={styles.tableRow}>Sec</th>
                            <th className={styles.tableRow}>Level</th>
                            <th className={styles.tableRow}>Elevation</th>
                            <th className={styles.tableRow}>Gutter</th>
                            <th className={styles.tableRow}>Leaders</th>
                            <th className={styles.tableRow}>Screens</th>
                            <th className={styles.tableRow}>Fascia</th>
                            <th className={styles.tableRow}>Capping</th>
                            <th className={styles.tableRow}>Heads</th>
                            <th>Action2</th>
                            {editSection && <th className={styles.tableRow}></th>}
                        </tr>
                    </>
                </thead>
                <tbody>
                    {
                        data.map((elem, index) => (
                            <tr key={index}>
                                <th scope="row">
                                    <button className="btn btn-primary" onClick={() => onView(elem)}>
                                        View
                                    </button>
                                </th>
                                <td className={styles.tableRow}>{index + 1}</td>
                                <td className={styles.tableRow}>{elem?.level}</td>
                                <td className={`${styles.tableRow} ${styles.whiteSpacePreWrap}`}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <span>
                                            {elem.elevation?.[0]}
                                        </span>
                                        <span>
                                            {elem?.elevationDetails?.type}
                                        </span>
                                    </div>
                                </td>
                                <td className={styles.tableRow}>
                                    {elem?.guttersAndLeaders?.gutters
                                        ? calculateTotalGutterMetric(elem.guttersAndLeaders.gutters, 'linearFeet')
                                        : null}
                                </td>
                                <td className={styles.tableRow}>
                                    {elem?.guttersAndLeaders?.leaders
                                        ? calculateTotalGutterMetric(elem.guttersAndLeaders.leaders, 'linearFeet')
                                        : null}
                                </td>
                                <td className={styles.tableRow}>
                                    {elem?.guttersAndLeaders?.screens
                                        ? calculateTotalGutterMetric(elem.guttersAndLeaders.screens, 'linearFeet')
                                        : null}
                                </td>
                              

                                <td className={styles.tableRow}>
                                    {elem?.fasciaAndCapping?.fascia
                                        ? calculateTotalGutterMetric(elem.fasciaAndCapping.fascia, 'linearFeet')
                                        : null}
                                </td>
                                <td className={styles.tableRow}>
                                    {elem?.fasciaAndCapping?.capping
                                        ? calculateTotalGutterMetric(elem.fasciaAndCapping.capping, 'linearFeet')
                                        : null}
                                </td>

                                <td className={styles.tableRow}>
                                    {elem?.guttersAndLeaders?.leaderHeads
                                        ? calculateTotalGutterMetric(elem.guttersAndLeaders.leaderHeads, 'count')
                                        : null}
                                </td>

                                <td>
                                    {metricData?.sectionId === elem?._id && (
                                        <button
                                            className={`tblDel btn btn-danger`}
                                            onClick={() => setShowDeleteModal(true)}
                                        >
                                            Delete
                                        </button>
                                    )}
                                </td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <DeleteModal
                deleteData={deleteSection}
                hideDeleteModal={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                heading="Section Delete Confirmation"
                showDeleteModal={showDeleteModal}
            />
        </div>
    );
};

GutterSectionListing.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    editSection: PropTypes.bool,
    requestId: PropTypes.string.isRequired,
};

export default GutterSectionListing;
