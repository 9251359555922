import { getLastElement } from "utils/utils";
import { metricsActionTypes } from "./metrics.types";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  errors: "",
  currentAddedSection: {},

  isLoadingAdd: false,
  addError: {},
  addResponse: {},

  isLoadingEdit: false,
  editError: {},
  editResponse: [],

  isLoadingDelete: false,
  deleteError: {},
  deleteResponse: {}
};

export const GuttersMetricV2Data = (state = INITIAL_STATE, action) => {
  // console.log("MetricV2Data", action);
  switch (action.type) {



    case metricsActionTypes.METRIC_GET_ROOFING_START:
      return {
        ...state,
        isLoading: true,
        data: {},
        errors: "",
      };

    case metricsActionTypes.METRIC_GET_ROOFING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        errors: "",
      };
      
      case metricsActionTypes.METRIC_GET_ROOFING_FAILURE:
        return {
          ...state,
          isLoading: false,
          data: {},
          errors: action.payload,
        };


    case metricsActionTypes.METRIC_ADD_ROOFING_START:
      return {
        ...state,
        isLoadingAdd: true,
        addResponse: {},
        addError: "",
      };
    case metricsActionTypes.METRIC_ADD_ROOFING_SUCCESS:
      return {
        ...state,
        isLoadingAdd: false,
        addError: "",
        currentAddedSection: getLastElement(action.payload),
        addResponse: getLastElement(action.payload),
        // data: {
        //   ...state.data,
        //   sections: [...action.payload],
        // },
      };
    case metricsActionTypes.METRIC_ADD_ROOFING_FAILURE:
      return {
        ...state,
        isLoadingAdd: false,
        addResponse: {},
        addError: action.payload,
      };






    case metricsActionTypes.SECTION_UPDATE_START:
      return {
        ...state,
        isLoadingEdit: true,
        editResponse: [],
        editError: "",
      };
    case metricsActionTypes.SECTION_UPDATE__SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        editResponse: action.payload,
        editError: "",
      };
    case metricsActionTypes.SECTION_UPDATE__FAILURE:
      return {
        ...state,
        isLoadingEdit: false,
        editResponse: [],
        editError: action.payload,
      };

      case metricsActionTypes.SECTION_DELETE_START:
        return {
          ...state,
          isLoadingDelete: true,
          deleteResponse: {},
          deleteError: "",
        };
      case metricsActionTypes.SECTION_DELETE__SUCCESS:
        return {
          ...state,
          isLoadingDelete: false,
          deleteResponse: action.payload,
          deleteError: "",
        };
      case metricsActionTypes.SECTION_DELETE__FAILURE:
        return {
          ...state,
          isLoadingDelete: false,
          deleteResponse: {},
          deleteError: action.payload,
        };
    default:
      return state;
  }
};
